import { RouteProps, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { Role } from '@/features/user';

type RouteGuardProps = {
  requiredRole: Role;
} & RouteProps;

export const RouteGuard = ({ requiredRole, children }: RouteGuardProps & { children: JSX.Element }): JSX.Element => {
  const { isAuthenticated, isLoading, user } = useAuth0();

  if (isLoading) {
    return (
      // todo: replace with fun loader
      <Typography variant="h3" sx={{ display: 'flex', justifyContent: 'center', m: 10 }}>
        Loading...
      </Typography>
    );
  }

  if (!isAuthenticated) {
    // user not logged in, redirect to login
    return <Navigate replace to="/login" state="/demo" />;
  }
  if (user && !user['prism/roles']?.includes(requiredRole)) {
    // user is logged in but doesn't have required role, redirect to request access
    return <Navigate to={`/request-access?${requiredRole}`} state={{ from: location.pathname }} />;
  }

  // user is logged in and has required role, grant access to route
  return children;
};
