import { Outlet } from 'react-router-dom';

import { Container, CssBaseline, Toolbar } from '@mui/material';
import { NavBar, NavBarMenuProvider, NavBarProvider } from '@/features/navbar';
import { ReactNode, useState } from 'react';

interface LayoutProvidersProps {
  children: ReactNode;
}

// establish all shared provider contexts that outlets need influence over
function LayoutProviders({ children }: LayoutProvidersProps) {
  const [navTitle, setNavTitle] = useState('');
  const [menuContent, setMenuContent] = useState<ReactNode>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <NavBarProvider value={{ title: navTitle, setTitle: setNavTitle }}>
      <NavBarMenuProvider
        value={{
          content: menuContent,
          setContent: setMenuContent,
          drawerOpen,
          setDrawerOpen
        }}
      >
        {children}
      </NavBarMenuProvider>
    </NavBarProvider>
  );
}

export default function Layout() {
  return (
    <LayoutProviders>
      <CssBaseline />
      <NavBar />
      <Toolbar />
      <Container>
        <Outlet />
      </Container>
    </LayoutProviders>
  );
}
