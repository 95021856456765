import { IconButton, Box } from '@mui/material';
import theme from '@/styles/theme';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ReactNode } from 'react';

/**
 * Chat layout for vault chat
 */
export const VaultChatLayout = ({
  isChatting,
  onChatClosed,
  children
}: {
  isChatting: boolean;
  onChatClosed: () => void;
  children?: ReactNode;
}): JSX.Element => {
  return (
    <Box
      sx={{
        width: isChatting ? '30%' : 0,
        transition: 'width 1200ms',
        visibility: isChatting ? 'visible' : 'hidden',
        backgroundColor: theme.palette.background.default,
        height: '100%',
        borderTopLeftRadius: theme.borderRadius,
        borderTopRightRadius: theme.borderRadius,
        position: 'relative',
        background: 'white',
        mt: 8,
        mr: !isChatting ? 5 : 0
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: -15,
          left: -15,
          color: theme.palette.blue.dark,
          filter: 'drop-shadow(0px 0px 74px rgba(0, 50, 98, 0.22))',
          height: 51,
          width: 51,
          backgroundColor: theme.palette.background.default
        }}
        onClick={onChatClosed}
      >
        <ArrowForwardIcon />
      </IconButton>
      <Box sx={{ m: 0, mt: 4, height: '100%' }}>{children}</Box>
    </Box>
  );
};
