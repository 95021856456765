import { Input } from '@mui/material';

type InputFieldProps = {
  input: string;
  setInput: (value: string) => void;
  handleSendMessage: (message: string) => void;
  disabled?: boolean;
};

export const InputField = ({ input, setInput, handleSendMessage, disabled }: InputFieldProps) => (
  <Input
    disabled={disabled}
    value={input}
    disableUnderline
    onChange={(e) => setInput(e.target.value)}
    onKeyPress={(e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage(input);
      }
    }}
    multiline
  />
);
