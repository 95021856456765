import { useNavBarMenu } from '@/features/navbar';
import theme from '@/styles/theme';
import { Box, CircularProgress, Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

type VaultMapItem = {
  tier: string;
  tierSortKey?: number;
  name: string;
  filterableKey: string;
  path: string;
};

type GroupedVaultMap = {
  [key: string]: VaultMapItem[];
};

// Organize vault map items by tier
const groupByTier = (data: VaultMapItem[]): GroupedVaultMap =>
  data.reduce((groupedData: { [key: string]: VaultMapItem[] }, item) => {
    const { tier } = item;
    if (!groupedData[tier]) {
      groupedData[tier] = [];
    }
    groupedData[tier].push(item);
    return groupedData;
  }, {});

// Filters vault data based on filterkey
const filterVaultMap = (data: VaultMapItem[], filterString?: string) => {
  if (!filterString) {
    return data;
  }
  const pattern = new RegExp(filterString.toLowerCase().replace(/\s+/g, ''));
  const filteredData = data.filter((r) => pattern.test(r.filterableKey));
  return filteredData;
};

const NavigationPanel = ({ data: groupedVaultData }: { data: GroupedVaultMap }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<{ [key: string]: boolean }>({});

  const handleToggle = (item: string) => {
    setOpen((prev) => ({ ...prev, [item]: !prev[item] }));
  };

  const { drawerOpen, setDrawerOpen } = useNavBarMenu();

  // open & highlight the item we're looking at
  const { tier, page } = useParams();
  useEffect(() => {
    setOpen({ [tier as string]: true });
  }, [tier]);

  const handleNavigation = (path: string) => {
    // if open, close the drawer. then, go to the new page
    if (drawerOpen) {
      setDrawerOpen(false);
      // delay the navigation to allow the drawer to close smoothly
      setTimeout(() => {
        navigate(path);
      }, 50); // just enough for the drawer to close
    } else {
      navigate(path); // if the drawer is not open, navigate immediately
    }
  };

  return (
    <List>
      {Object.entries(groupedVaultData).map(([category, data]) => (
        <div key={category}>
          <ListItemButton
            onClick={() => handleToggle(category)}
            sx={{ paddingTop: 0.5, paddingBottom: 0.5, paddingLeft: '9%' }}
          >
            <ListItemText
              primary={`${category} (${data.length})`}
              primaryTypographyProps={{ variant: 'body2', sx: { fontWeight: 'bold' } }}
            />
            {data.length ? (open[category] ? '↓' : '') : null}
          </ListItemButton>
          {data.length > 0 && (
            <Collapse in={open[category]} timeout="auto" unmountOnExit>
              <List
                component="div"
                disablePadding
                sx={{
                  maxHeight: 275,
                  overflow: 'scroll',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none'
                  }
                }}
              >
                {data.map((item) => (
                  <ListItemButton
                    // scrolls the item into view on load
                    autoFocus={page === item.name}
                    id={item.name}
                    key={item.name}
                    style={{
                      borderRadius: theme.borderRadius,
                      paddingLeft: '15%',
                      paddingTop: 0.3,
                      paddingBottom: 0.3,
                      backgroundColor:
                        item.name === page ? 'rgba(255, 226, 148, 0.5)' : theme.palette.background.default
                    }}
                    onClick={() => handleNavigation(item.path)}
                  >
                    <ListItemText primary={item.name} primaryTypographyProps={{ variant: 'body2' }} />
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          )}
        </div>
      ))}
    </List>
  );
};

export const VaultMap = ({ filter }: { filter?: string }): JSX.Element => {
  const [data, setData] = useState<VaultMapItem[]>([]);
  const [groupedData, setGroupedData] = useState<{ [key: string]: VaultMapItem[] }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const location = useLocation();
  const segments = location.pathname.split('/').filter(Boolean);
  const segmentsRef = useRef(segments);

  useEffect(() => {
    const url = `https://vault.prism.bio/${segmentsRef.current[1]}/inventory.json`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('failed to fetch map');
        }
        return response.json();
      })
      .then((jsonData) => {
        setData(jsonData);
        setGroupedData(groupByTier(jsonData));
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const filteredData = groupByTier(filterVaultMap(data, filter));
    setGroupedData(filteredData);
  }, [filter, data]);

  if (loading) {
    return (
      <div>
        <CircularProgress
          size={48}
          sx={{
            position: 'absolute',
            top: '30%', // Move the spinner's top pivot point up
            left: 'calc(50% - 24px)', // horizontally center
            transform: 'translate(-50%, -50%)', // Center based on the new pivot
            zIndex: 2
          }}
        />
      </div>
    );
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box>
      <NavigationPanel data={groupedData} />
    </Box>
  );
};
