import { DataGrid } from '@/components';
import { Box, Dialog, IconButton, Tooltip, Typography } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { ReactNode, useState } from 'react';
import { Data } from '../../demo-data';
import { isEmpty } from 'lodash';
import { createSentenceFromArray } from '@/utils/string-utils';

export type AuditProps = {
  dataset: Data;
  chartProps: Record<string, unknown>;
  chartComponent: ReactNode;
  chartType: string;
  optionalProps?: Record<string, unknown>;
  requiredProps: Record<string, unknown>;
};

/**
 * Creates chart description based on properties and metadata
 */
// todo: replace this w/ generated text or a better version of this
const createChartDescription = ({
  chartProps,
  dataset,
  chartType,
  optionalProps,
  requiredProps
}: Omit<AuditProps, 'chartComponent'>) => {
  const dataSummary = `The dataset for this chart is called ${
    dataset.name
  }. It includes columns ${createSentenceFromArray(Object.keys(dataset.values[0]))}.`;

  let fieldInfo = '';
  if (isEmpty(requiredProps)) {
    fieldInfo =
      'no data fields are currently selected. Make data field selections in the chart builder to see a more interesting chart.';
  } else {
    fieldInfo = createSentenceFromArray(
      Object.keys(requiredProps).map((field) => {
        if (!chartProps[field]) {
          return `${field} is not yet defined`;
        }
        return `${field} is set to ${
          chartProps[`${field}Aggregate`]
            ? `the ${chartProps[`${field}Aggregate`]} of ${chartProps[field]}`
            : chartProps[field]
        }`;
      })
    );
  }

  const chartDescription = `This is a ${chartType.toLowerCase()} chart where ${fieldInfo}.`;

  const usedOptionalProps = optionalProps ? Object.keys(optionalProps).filter((key) => chartProps[key]) : [];

  const additionalInfo =
    usedOptionalProps.length >= 1
      ? `This chart also has additional properties set including ${createSentenceFromArray(usedOptionalProps)}.`
      : '';

  const fullText = `${dataSummary} ${chartDescription} ${additionalInfo}`;
  return fullText;
};

/**
 * Audit to let user how a chart is made
 */
export const Audit = ({ dataset, chartProps, chartComponent, requiredProps, optionalProps, chartType }: AuditProps) => {
  const [showAudit, setShowAudit] = useState(false);
  return (
    <Box>
      <Tooltip title="Audit">
        <IconButton onClick={() => setShowAudit(true)}>
          <ZoomInIcon />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth maxWidth="md" open={showAudit} onClose={() => setShowAudit(false)}>
        <Box sx={{ m: 5 }}>
          <Box sx={{ mt: 3, mb: 3 }}>
            <Typography variant="h4">Methods</Typography>
            <Typography variant="body1">{dataset.methods}</Typography>
          </Box>
          <hr />
          <Box sx={{ mt: 3, mb: 3 }}>
            <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
              Chart Preview & Description
            </Typography>
            <Box display="flex" justifyContent="center">
              {chartComponent}
            </Box>
            <Typography variant="body1">
              {createChartDescription({
                chartProps,
                dataset,
                chartType,
                optionalProps,
                requiredProps
              })}
            </Typography>
          </Box>
          <hr />
          <Box sx={{ mt: 3, mb: 3 }}>
            <Typography variant="h4" sx={{ mt: 3, mb: 3 }}>
              Data
            </Typography>
            <DataGrid
              columns={Object.keys(dataset.values[0]).map((field) => ({
                field,
                title: field
              }))}
              rows={dataset.values}
            />
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
