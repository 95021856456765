import { Typography } from '@mui/material';
import { Page } from '../components/page';

export default function Project() {
  return (
    <Page>
      <Typography variant="h3">Dummy Project!</Typography>
    </Page>
  );
}
