import { LinkedPrismCard } from '@/components/prism-card';
import { usePostApiWithoutAuth } from '@/hooks/use-post-api-without-auth';
import theme from '@/styles/theme';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, Grid, PaletteColor, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DHTVaultItemType, VaultItemSearchResult } from './types';
import { SessionStorageKey, getItemFromSessionStorage, setItemInSessionStorage } from '@/stores/session-storage-utils';

// map theme colors to dht types for card styling
const VaultColorMap: { [key in DHTVaultItemType]: PaletteColor } = {
  Devices: theme.palette.green,
  Manufacturers: theme.palette.orange,
  Classes: theme.palette.purple,
  Subclasses: theme.palette.blue,
  Components: theme.palette.pink
};

const LoadingOverlay = () => {
  return (
    <Box
      sx={{
        color: theme.palette.blue.main,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // semi-transparent white
        zIndex: 1
      }}
    >
      <CircularProgress size={48} />
    </Box>
  );
};

// Content that will appear in search result cards
const VaultSearchResultContent = ({ result }: { result: VaultItemSearchResult }) => {
  return (
    <Box sx={{ position: 'relative', maxHeight: '125px', overflow: 'hidden' }}>
      <Box sx={{ mt: 1, mb: 1 }}>
        <Box>
          <Typography variant="body2" sx={{ fontSize: 10, fontWeight: 600 }}>
            Summary
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 10, mt: 0.5 }}>
            {result.preview_content}
          </Typography>
        </Box>
      </Box>
      <Box
        component="span"
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '10px' /* Adjust this for the fade length */,
          backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.2), white)'
        }}
      />
    </Box>
  );
};

export const SearchView = ({ query }: { query: string }) => {
  const [results, setResults] = useState<VaultItemSearchResult[]>([]);
  const [loading, setLoading] = useState(true);

  const { isAuthenticated, user } = useAuth0();
  const { vaultName = '' } = useParams();
  const { postData } = usePostApiWithoutAuth<VaultItemSearchResult[]>('vault/public/similarity-search');

  // Fetch search results if query changes
  useEffect(() => {
    const fetchResults = async () => {
      setLoading(true);

      // First, check sessionStorage
      const cachedResults = getItemFromSessionStorage(SessionStorageKey.VaultSearchResults);
      if (cachedResults && JSON.parse(cachedResults).query === query) {
        setResults(JSON.parse(cachedResults).results);
        setLoading(false);
      } else {
        // fall back to the API if we need the data
        try {
          const fetchedResults = await postData({
            query: query,
            vaultId: vaultName,
            ...(isAuthenticated && { userId: user?.sub })
          });
          setItemInSessionStorage(
            SessionStorageKey.VaultSearchResults,
            JSON.stringify({ query, results: fetchedResults })
          );
          setResults(fetchedResults);
        } catch (error) {
          console.error('Failed to fetch data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchResults();
  }, [query, isAuthenticated, user, vaultName, postData]);

  return (
    <Box sx={{ width: '100%', mt: 1, mb: 45 }}>
      {loading && <LoadingOverlay />}
      {results?.length ? (
        <Grid container rowSpacing={35} justifyContent="space-evenly">
          {results?.map((result: VaultItemSearchResult) => (
            <Grid key={result.name} item minWidth={235}>
              <LinkedPrismCard
                link={`/vaults/dht/${result.tier}/${encodeURIComponent(result.name)}?search=${query}`}
                color={VaultColorMap[result.tier]}
                content={<VaultSearchResultContent result={result} />}
                title={result.name}
                label={result.tier}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: {
              xs: 5
            }
          }}
        >
          <Typography variant="h3">No Results</Typography>
          <Typography variant="body1">
            There are no current results matching that query. Please refine your search.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
