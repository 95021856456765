import { SendMessageParams, useAgentChat } from '@/hooks/use-agent-chat';
import { FileSimilaritySearchResult } from './results';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AgentToInteractWith, ErrorMessage, InputField, SendButton } from '@/features/chatbot';
import { Card, List, ListItem, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import MuiMarkdown, { getOverrides } from 'mui-markdown';

const htmlElements = ['p', 'h1', 'h2', 'h3', 'span', 'li', 'ol', 'ul', 'a'];

export const ChatWithSearchResults = ({
  query,
  results,
  projectId
}: {
  query: string;
  results: FileSimilaritySearchResult[];
  projectId: string;
}) => {
  const { loading, messages, error, sendMessage } = useAgentChat({ initialMessages: [], projectId });
  const initialized = useRef(false);
  const [input, setInput] = useState('');
  const theme = useTheme();

  const handleSendMessage = useCallback(
    async (message: string) => {
      const params: SendMessageParams = {
        agent: AgentToInteractWith.DocChat,
        message,
        // limit results to 8000 characters
        results: JSON.stringify(
          results.map((result) => ({
            text: result.content ?? '',
            doc: result.sourceFile ?? '',
            page: result.sourcePage ?? ''
          }))
        ).slice(0, 8000),
        query
      };
      // Ignore empty messages
      if (message.trim() === '' || loading) return;

      if (!loading) {
        setInput('');
        // disable the input field while the message is being sent
        await sendMessage(params); // todo only get rid of files on upload success
      }
    },
    [loading, query, results, sendMessage]
  );

  // Send first message when component mounts
  useEffect(() => {
    if (initialized.current) return;

    if (messages.length === 0) {
      handleSendMessage(query);
    }

    initialized.current = true;
  }, [projectId, handleSendMessage, messages, query]);

  // Scroll to bottom when messages change
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };
  useEffect(scrollToBottom, [messages]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box
          sx={{
            overflowY: 'auto',
            marginBottom: theme.spacing(3),
            scrollbarWidth: 'none', // Hide the scrollbar for firefox
            '&::-webkit-scrollbar': {
              display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
            },
            '&-ms-overflow-style:': {
              display: 'none' // Hide the scrollbar for IE
            }
          }}
        >
          <List>
            {messages.map((msg, index) => (
              <ListItem
                key={index}
                sx={{
                  justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start'
                }}
              >
                <Card
                  variant="outlined"
                  sx={{
                    p: theme.spacing(2),
                    maxWidth: '90%',
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    backgroundColor: msg.role === 'user' ? theme.palette.info.light : theme.palette.secondary.light
                  }}
                >
                  <Typography variant={'body2'}>
                    <MuiMarkdown
                      overrides={{
                        ...getOverrides({}),
                        ...htmlElements.reduce(
                          (acc, element) => ({
                            ...acc,
                            [element]: {
                              component: element,
                              props: {
                                style: theme.typography.body2
                              }
                            }
                          }),
                          {}
                        )
                      }}
                    >
                      {msg.content}
                    </MuiMarkdown>
                  </Typography>
                </Card>
              </ListItem>
            ))}
          </List>
          <ErrorMessage error={error} />
          <div style={{ height: 40 }} ref={messagesEndRef} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          backgroundColor: theme.palette.background.default,
          position: 'sticky',
          bottom: 0,
          borderTop: `1px solid ${theme.palette.divider}`
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: '1000px',
            padding: theme.spacing(3),
            paddingTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <InputField input={input} setInput={setInput} handleSendMessage={handleSendMessage} disabled={loading} />
        </Box>
        <SendButton handleSendMessage={handleSendMessage} input={input} />
      </Box>
    </Box>
  );
};
