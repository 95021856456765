import { Box, IconButton, Skeleton, Tooltip } from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { StyledCard } from '../../../components/styled-card';

export const AddProjectButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showHoverEffect, setShowHoverEffect] = useState(false);
  return (
    <Box>
      <Tooltip title="Add New Project">
        <Link to="/demo">
          <Box sx={{ position: 'relative' }}>
            <StyledCard
              sx={{
                backgroundColor: 'lightgrey',
                display: 'flex',
                justifyContent: 'space-between'
              }}
              elevation={0}
              variant="outlined"
              onMouseOverCapture={() => setShowHoverEffect(true)}
              onMouseOutCapture={() => setShowHoverEffect(false)}
            >
              {showHoverEffect && (
                <Skeleton
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    bgcolor: 'rgba(255, 255, 255, 0.4)'
                  }}
                  animation="wave"
                  width="100%"
                  height="100%"
                  variant="rectangular"
                />
              )}
              <IconButton onClick={() => setIsOpen(!isOpen)} sx={{ m: 'auto' }}>
                <PostAddIcon htmlColor="darkslategray" fontSize="large" />
              </IconButton>
            </StyledCard>
          </Box>
        </Link>
      </Tooltip>
    </Box>
  );
};
