export enum Role {
  DEMO = 'demo_allowed'
}

export type UserProfile = {
  firstName: string;
  lastName: string;
  email: string;
  emailVerified: boolean;
  userId: string;
};
