import theme from '@/styles/theme';
import { ListItem, Card, Typography } from '@mui/material';

export const ErrorMessage = ({ error }: { error: Error | undefined }) =>
  error ? (
    <ListItem sx={{}}>
      <Card variant="outlined" sx={{ p: theme.spacing(2), maxWidth: '90%' }}>
        <Typography variant="body1">{JSON.stringify(error)}</Typography>
      </Card>
    </ListItem>
  ) : null;
