import { Box, Button, CircularProgress, useTheme } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

type FileInputButtonProps = {
  isUploading: boolean;
  handleFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export const FileInputButton: React.FC<FileInputButtonProps> = ({ isUploading, handleFileUpload }) => {
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={1} mb={1}>
      <input
        onChange={async (event) => {
          await handleFileUpload(event);
        }}
        accept=".pdf,.ppt,.pptx"
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
      />
      <label htmlFor="raised-button-file">
        <Button
          disabled={isUploading}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '80px',
            width: '350px',
            mt: 5,
            mb: 5,

            background: 'white', // Light grey background
            border: `1px solid ${theme.palette.grey[400]}`, // Darker grey border
            borderRadius: '8px', // Rounded corners
            color: '#757575', // Even darker grey text/icon color
            '&:hover': {
              background: 'rgba(227, 232, 235, 0.67)' // Slightly darker grey on hover
            }
          }}
          component="span"
          size="small"
        >
          {isUploading ? (
            <CircularProgress color="primary" />
          ) : (
            <AddCircleOutlineIcon color="primary" fontSize="large" />
          )}
        </Button>
      </label>
    </Box>
  );
};
