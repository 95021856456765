import theme from '@/styles/theme';
import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

export type ExhibitViewBoxProps = { title: string; children: ReactNode };

/**
 * Box wrapper for exhibit builder
 */
export const ExhibitViewBox = ({ title, children }: ExhibitViewBoxProps): JSX.Element => {
  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" zIndex={2}>
        <Box
          sx={{
            width: 'fit-content',
            bgcolor: theme.palette.background.default,
            pl: 5,
            pr: 5
          }}
        >
          <Typography variant="h5">{title}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          border: `1px solid ${theme.palette.grey[400]}`,
          borderRadius: 1,
          p: 3,
          mt: -2
        }}
      >
        {children}
      </Box>
    </>
  );
};
