import theme from '@/styles/theme';
import { ExpandMore } from '@mui/icons-material';
import { Button, IconButton, SwipeableDrawer, TextField, useMediaQuery } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box, Theme } from '@mui/system';
import { useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { ChatWithVault } from './chat/vault-chat';
import { VaultChatLayout } from './chat/vault-chat-layout';
import { VaultSearchBox } from './search';
import { VaultLayoutScrollContext } from '../stores/vault-context';

// Text field with custom colors from new theme
export const StyledTextField = withStyles({
  root: {
    '& label': { color: theme.palette.blue.main },
    '& label.Mui-focused': {
      color: theme.palette.blue.main
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.blue.main
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.blue.main
    }
  }
})(TextField);

// layout for vault item currently in view
export const VaultLayout = () => {
  const [isChatting, setIsChatting] = useState(false);
  const scrollContainerRef = useRef(null);
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{ height: 'calc(100vh - 50px)', pt: 2, pb: isSmallScreen ? theme.spacing(7) : 0 }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          mr: isChatting && !isSmallScreen ? 2 : 0,
          // larger ml (5) when just viewing vault item on md screen, smaller ml (2) when md screen with chat open, and no ml on small screen
          ml: isMdScreen && !isChatting && !isSmallScreen ? 5 : isMdScreen && isChatting ? 2 : 0,
          width: isChatting && !isSmallScreen ? '70%' : '100%',
          transition: 'width 1200ms',
          position: 'relative'
        }}
      >
        <VaultSearchBox />
        <VaultLayoutScrollContext.Provider value={scrollContainerRef}>
          <Box
            ref={scrollContainerRef}
            sx={{
              height: '100%',
              borderTopLeftRadius: theme.borderRadius,
              borderTopRightRadius: theme.borderRadius,
              top: 80,
              position: 'absolute',
              bottom: 0,
              overflowY: 'scroll',
              width: { xs: '100vw', sm: '100%' }, // 100vw on extra-small screens and 100% on small screens and up
              // hide the native scrollbar until we have a better style for it.
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              },
              '&-ms-overflow-style:': {
                display: 'none'
              }
            }}
          >
            <Outlet />
            <Box>
              <Button
                sx={{
                  color: theme.palette.pink.dark,
                  backgroundColor: theme.palette.pink.main,
                  position: 'fixed',
                  bottom: 30,
                  right: 50,
                  height: '70px',
                  width: '70px',
                  fontFamily: 'Aeonik',
                  fontSize: 28,
                  filter: 'drop-shadow(0px 0px 20px rgba(255, 228, 222, 0.67))',
                  borderRadius: '100%',
                  display: isChatting ? 'none' : 'block'
                }}
                onClick={() => setIsChatting(true)}
                disabled={isChatting}
              >
                ✦
              </Button>
            </Box>
          </Box>
        </VaultLayoutScrollContext.Provider>
      </Box>
      {!isSmallScreen ? (
        <VaultChatLayout isChatting={isChatting} onChatClosed={() => setIsChatting(false)}>
          <ChatWithVault />
        </VaultChatLayout>
      ) : (
        <SwipeableDrawer
          sx={{
            '& .MuiDrawer-paper': {
              maxHeight: '80vh',
              backgroundColor: theme.palette.background.default,
              borderTopLeftRadius: theme.borderRadius,
              borderTopRightRadius: theme.borderRadius,
              borderTop: `8px solid ${theme.palette.red.main}`, // This gives the top border
              boxShadow: `0px -1px 0px ${theme.palette.red.main} inset` // This adds the trace around the rounded edges
            }
          }}
          anchor={'bottom'}
          onOpen={() => setIsChatting(true)}
          open={isChatting}
          onClose={() => setIsChatting(false)}
        >
          <Box
            sx={{
              display: 'flex', // Use flex to center the icon horizontally
              justifyContent: 'center', // center the icon
              alignItems: 'center', // center the icon
              height: '48px', // height for the clickable area
              width: '100%', // ensure the box takes the full width
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: theme.palette.background.default
            }}
            onClick={() => setIsChatting(false)} // Move the click handler to the Box
          >
            <IconButton>
              <ExpandMore fontSize={'large'} sx={{ color: theme.palette.pink.dark }} />
            </IconButton>
          </Box>
          <ChatWithVault />
        </SwipeableDrawer>
      )}
    </Box>
  );
};
