import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';
import SyncIcon from '@mui/icons-material/Sync';
import emptyChartImg from './../../../../assets/empty-chart.png';
import { Audit, AuditProps } from '../audit';
import { Data } from '../../demo-data';

type PreviewProps = {
  chartComponent: ReactNode | null;
  resetChart: () => void;
  dataset: Data;
};

/**
 * Component for chart preview with audit
 */
export const ChartPreviewWithAudit = ({
  chartComponent,
  resetChart,
  dataset,
  chartProps,
  requiredProps,
  optionalProps,
  chartType
}: PreviewProps & AuditProps) => {
  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="caption">Preview</Typography>
        <Tooltip title="Reset Chart">
          <IconButton onClick={resetChart}>
            <SyncIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <hr />
      <Box sx={{ position: 'relative' }}>
        <Box display="flex" justifyContent="center" sx={{ m: 3 }}>
          {chartComponent ? (
            chartComponent
          ) : (
            <img src={emptyChartImg} alt="No chart data available" style={{ maxHeight: '300px', maxWidth: '300px' }} />
          )}
        </Box>

        {chartComponent && (
          <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
            <Audit
              dataset={dataset}
              chartType={chartType}
              optionalProps={optionalProps}
              requiredProps={requiredProps}
              chartProps={chartProps}
              chartComponent={chartComponent}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
