import { VegaLite } from 'react-vega';
import { ColorScheme } from 'vega';

/**
 * Temporary simple chart components to demo exhibit builder functionality
 *
 * TODO: replace these with our charts from 'prism-charts'
 */
export const Bar = ({
  data,
  xField,
  yField,
  colorField,
  yAxisTitle,
  xAxisTitle,
  colorAxisTitle,
  colorScheme,
  yFieldAggregate
}: {
  data: unknown[];
  xField: string;
  yField: string;
  colorField?: string;
  yAxisTitle?: string;
  xAxisTitle?: string;
  colorAxisTitle?: string;
  colorScheme?: string;
  yFieldAggregate?: 'sum' | 'count';
}): JSX.Element => (
  <VegaLite
    spec={{
      $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
      data: {
        values: data
      },
      mark: 'bar',
      encoding: {
        x: { field: xField, axis: { title: xAxisTitle } },
        y: {
          field: yField,
          type: 'quantitative',
          axis: { title: yAxisTitle },
          aggregate: yFieldAggregate
        },
        color: {
          field: colorField,
          legend: { title: colorAxisTitle },
          scale: { scheme: colorScheme as ColorScheme }
        }
      }
    }}
  />
);

export const Pie = ({
  data,
  sizeField,
  colorField,
  yAxisTitle,
  colorAxisTitle,
  colorScheme,
  sizeFieldAggregate
}: {
  data: unknown[];
  sizeField: string;
  colorField?: string;
  yAxisTitle?: string;
  colorAxisTitle?: string;
  colorScheme?: string;
  sizeFieldAggregate?: 'sum' | 'count';
}): JSX.Element => (
  <VegaLite
    spec={{
      $schema: 'https://vega.github.io/schema/vega-lite/v5.json',
      data: {
        values: data
      },
      mark: 'arc',
      encoding: {
        theta: {
          field: sizeField,
          type: 'quantitative',
          title: yAxisTitle,
          aggregate: sizeFieldAggregate
        },
        color: {
          field: colorField,
          legend: { title: colorAxisTitle },
          scale: { scheme: colorScheme as ColorScheme }
        }
      }
    }}
  />
);
