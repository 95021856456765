import { Link } from 'react-router-dom';
import { StyledCard } from '../../../components/styled-card';

export const ProjectCard = ({ imageSrc, url, title }: { imageSrc: string; url: string; title: string }) => {
  return (
    <Link to={url}>
      <StyledCard elevation={0} variant="outlined">
        <img src={imageSrc} alt={title} />
      </StyledCard>
    </Link>
  );
};
