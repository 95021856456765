import { DataGrid } from '@/components';
import { useGetApi } from '@/hooks/use-get-api';
import DualRing from '@/styles/loaders/dual-ring';
import theme from '@/styles/theme';
import { FirestoreCollection, FirestoreSubCollection } from '@/types/firestore';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { Timestamp, collection, getFirestore, onSnapshot } from 'firebase/firestore';
import { memo, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// #region interfaces
interface FileData {
  id: string;
  name: string;
  createdAt: Timestamp;
  processingStatus?: {
    uploading?: {
      successMsg?: string;
      errorMsg?: string;
    };
    parsing?: {
      successMsg?: string;
      errorMsg?: string;
    };
    training?: {
      successObj?: TrainingSuccessObj;
      errorObj?: TrainingErrorObj;
    };
  };
}

interface RealtimeListenerToken {
  customToken: string;
}

interface TrainingSuccessObj {
  messageSuffix: string; // e.g. 'vectors loaded'
  propToSum: string; // e.g. 'numVectors', whatever this path is will accumulate a value for rendering
  pageCount?: number;
  pages?: {
    [pageNumber: number]: {
      [key: string]: number; // This is the index signature
      pageNumber: number;
      numVectors: number; // how many vectors were trained
    };
  };
}

interface TrainingErrorObj {
  pages?: {
    [pageNumber: number]: {
      pageNumber: number;
      errorMessage: string; // communication about why that page failed to be trained
    };
  };
}
// #endregion interfaces

// #region types
type UndefinedOrBasicMessageStructure =
  | {
      successMsg?: string | undefined;
      errorMsg?: string | undefined;
    }
  | undefined;

type UndefinedOrObjMessageStructure =
  | {
      successObj?: TrainingSuccessObj;
      errorObj?: TrainingErrorObj;
    }
  | undefined;
// #endregion types

// #region constants
// some shared constants for modifying table styles all at once
const PRIMARY_FONT_SIZE = '1.7rem';
const CELL_FONT_SIZE = '0.8rem';
const HEADER_FONT_SIZE = '1rem';
const HEADER_WITH_HELP_TYPOGRAPHY = { cursor: 'help', m: 1, fontSize: HEADER_FONT_SIZE, fontWeight: '300' };
const BASE_COLUMN_PROPS: Partial<GridColDef> = { align: 'center', headerAlign: 'center', sortable: true };
// #endregion constants

// #region status renderers
const renderUploadingStatus = (state: UndefinedOrBasicMessageStructure) => {
  if (!state) {
    return <DualRing />;
  } else if (state.successMsg) {
    return (
      <Typography variant="h2" sx={{ color: theme.palette.success.dark, fontSize: CELL_FONT_SIZE, fontWeight: '500' }}>
        {state.successMsg}
      </Typography>
    );
  } else if (state.errorMsg) {
    return (
      <Typography variant="h2" sx={{ color: theme.palette.error.dark, fontSize: CELL_FONT_SIZE, fontWeight: '500' }}>
        {state.errorMsg}
      </Typography>
    );
  }
};

const renderParsingStatus = (state: UndefinedOrBasicMessageStructure) => {
  if (!state) {
    return <DualRing />;
  } else if (state?.successMsg) {
    return (
      <Typography variant="h2" sx={{ color: theme.palette.success.dark, fontSize: CELL_FONT_SIZE, fontWeight: '500' }}>
        {state.successMsg}
      </Typography>
    );
  } else if (state?.errorMsg) {
    return (
      <Typography variant="h2" sx={{ color: theme.palette.error.dark, fontSize: CELL_FONT_SIZE, fontWeight: '500' }}>
        {state.errorMsg}
      </Typography>
    );
  }
};

function getAccumulatedVectors(obj: TrainingSuccessObj): number {
  // Ensure there are pages to process
  if (!obj.pages) return 0;

  // Accumulate numVectors from all pages
  const propToSum = obj.propToSum as string;
  return Object.values(obj.pages).reduce((accum, page) => accum + page[propToSum], 0);
}

const renderTrainingStatus = (state: UndefinedOrObjMessageStructure) => {
  if (!state) {
    return <DualRing />;
  }
  if (state.errorObj?.pages) {
    return (
      <Typography variant="h2" sx={{ color: theme.palette.error.dark, fontSize: CELL_FONT_SIZE, fontWeight: '500' }}>
        ERROR
      </Typography>
    );
  } else if (state.successObj?.pages) {
    const color =
      state.successObj.pageCount === Object.keys(state.successObj.pages).length
        ? theme.palette.success.dark
        : theme.palette.warning.dark;
    return (
      <Typography variant="h2" sx={{ color, fontSize: CELL_FONT_SIZE, fontWeight: '500' }}>
        {getAccumulatedVectors(state.successObj)} {state.successObj.messageSuffix}
      </Typography>
    );
  } else {
    return <DualRing />;
  }
};
// #endregion status renderers

// #region comparators
// used for comparing basic success or error messages
function basicSuccessOrErrorComparator(
  valueA: UndefinedOrBasicMessageStructure,
  valueB: UndefinedOrBasicMessageStructure
) {
  const stringValueA = String(valueA?.successMsg || valueA?.errorMsg || '');
  const stringValueB = String(valueB?.successMsg || valueB?.errorMsg || '');
  return stringValueA.localeCompare(stringValueB);
}

// used to sort vectors loaded
function trainingComparator(valueA: UndefinedOrObjMessageStructure, valueB: UndefinedOrObjMessageStructure) {
  const numVectorsA = valueA?.successObj ? getAccumulatedVectors(valueA?.successObj) : 0;
  const numVectorsB = valueB?.successObj ? getAccumulatedVectors(valueB?.successObj) : 0;
  return numVectorsA - numVectorsB;
}
// #endregion comparators

export const FilesStatusTable = memo(() => {
  const { projectId } = useParams<{ projectId: string }>();

  const [areFilesBeingSubscribedTo, setAreFilesBeingSubscribedTo] = useState(true);
  const [firestoreToken, setFirestoreToken] = useState<string | null>(null);
  const [filesData, setFilesData] = useState<FileData[]>([]);

  const rows = useMemo(
    () =>
      filesData.map((file) => ({
        id: file.id,
        createdAt: file.createdAt,
        name: file.name,
        uploading: file.processingStatus?.uploading,
        parsing: file.processingStatus?.parsing,
        training: file.processingStatus?.training
      })),
    [filesData]
  );

  const [realtimeCredentials] = useGetApi<RealtimeListenerToken>('auth/realtime-listener-token');

  useEffect(() => {
    if (realtimeCredentials?.customToken) {
      setFirestoreToken(realtimeCredentials.customToken);
    }
  }, [realtimeCredentials]);

  useEffect(() => {
    let unsub: (() => void) | null = null; // Initialize unsub outside the async function so we can unsubscribe later
    let timeoutId: number | null = null;

    async function signInAndSubscribe(token: string, projectId: string) {
      const auth = getAuth();
      await signInWithCustomToken(auth, token);
      const db = getFirestore();
      unsub = onSnapshot(
        collection(db, FirestoreCollection.Projects, projectId, FirestoreSubCollection.Files),
        (coll) => {
          coll.docChanges().forEach((change) => {
            const updatedFileDoc = change.doc.data() as FileData;
            setFilesData((prevFiles) => {
              const existingFile = prevFiles.find((file) => file.id === updatedFileDoc.id);
              // If it exists, update it
              if (existingFile) {
                return prevFiles.map((file) => (file.id === updatedFileDoc.id ? updatedFileDoc : file));
              }
              // Otherwise, add the new file to the array
              return [...prevFiles, updatedFileDoc];
            });
            setAreFilesBeingSubscribedTo(false);
          });
        }
      );
      return unsub;
    }

    if (firestoreToken && projectId) {
      signInAndSubscribe(firestoreToken, projectId)
        .then(() => {
          // allow Firestore data to resolve before setting the loading state to false
          timeoutId = window.setTimeout(() => {
            setAreFilesBeingSubscribedTo(false);
          }, 1500);
        })
        .catch(() => {
          throw new Error('Unable to subscribe to realtime');
        });
    }

    return () => {
      // Call the unsubscribe function if it exists
      if (unsub) {
        unsub();
      }
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [firestoreToken, projectId]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...BASE_COLUMN_PROPS,
        field: 'name',
        headerName: 'File Name',
        width: 200,
        renderHeader: () => (
          <Typography variant="h2" sx={{ m: 1, fontSize: HEADER_FONT_SIZE, fontWeight: 300 }}>
            File Name
          </Typography>
        ),
        renderCell: (params: unknown) => {
          const state = params as { value: string };
          return (
            <Typography
              variant="h3"
              sx={{ fontSize: CELL_FONT_SIZE, overflow: 'hidden', textOverflow: 'ellipsis' }}
              title={state.value as string}
            >
              {state.value}
            </Typography>
          );
        }
      },
      {
        ...BASE_COLUMN_PROPS,
        field: 'createdAt',
        headerName: 'Upload Date',
        width: 150,
        renderHeader: () => (
          <Typography variant="h2" sx={{ m: 1, fontSize: HEADER_FONT_SIZE, fontWeight: 300 }}>
            Upload Date
          </Typography>
        ),
        renderCell: (params: unknown) => {
          {
            const state = params as { value: Timestamp };
            const displayFormatter = new Intl.DateTimeFormat('en-US', {
              month: 'short',
              day: '2-digit'
            });
            const formattedDisplayDate = displayFormatter.format(state.value.toDate());
            const titleFormatter = new Intl.DateTimeFormat('en-US', {
              year: 'numeric',
              month: 'long',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true
            });
            const formattedTitleDate = titleFormatter.format(state.value.toDate());
            return (
              <Typography variant="h3" sx={{ fontSize: CELL_FONT_SIZE }} title={formattedTitleDate}>
                {formattedDisplayDate}
              </Typography>
            );
          }
        }
      },
      {
        ...BASE_COLUMN_PROPS,
        field: 'uploading',
        headerName: 'Uploading',
        sortComparator: basicSuccessOrErrorComparator,
        width: 150,
        renderHeader: () => (
          <Tooltip title="File is being transferred to our secure storage.">
            <Typography variant="h3" sx={{ ...HEADER_WITH_HELP_TYPOGRAPHY }}>
              Uploading
            </Typography>
          </Tooltip>
        ),
        renderCell: (params: unknown) => {
          const state = params as
            | {
                value: {
                  successMsg?: string | undefined;
                  errorMsg?: string | undefined;
                };
              }
            | undefined;
          return renderUploadingStatus(state?.value);
        }
      },
      {
        ...BASE_COLUMN_PROPS,
        field: 'parsing',
        headerName: 'Parsing',
        sortComparator: basicSuccessOrErrorComparator,
        width: 150,
        renderHeader: () => (
          <Tooltip title="File is being divided into manageable sections for processing.">
            <Typography variant="h3" sx={{ ...HEADER_WITH_HELP_TYPOGRAPHY }}>
              Parsing
            </Typography>
          </Tooltip>
        ),
        renderCell: (params: unknown) => {
          const state = params as
            | {
                value: {
                  successMsg?: string | undefined;
                  errorMsg?: string | undefined;
                };
              }
            | undefined;
          return renderParsingStatus(state?.value);
        }
      },
      {
        ...BASE_COLUMN_PROPS,
        field: 'training',
        headerName: 'Training',
        sortComparator: trainingComparator,
        width: 150,
        renderHeader: () => (
          <Tooltip title="File contents are being used to train conversational AI.">
            <Typography variant="h3" sx={{ ...HEADER_WITH_HELP_TYPOGRAPHY }}>
              Training
            </Typography>
          </Tooltip>
        ),
        renderCell: (params: unknown) => {
          const state = params as
            | undefined
            | {
                value: {
                  successObj?: TrainingSuccessObj;
                  errorObj?: TrainingErrorObj;
                };
              };
          return renderTrainingStatus(state?.value);
        }
      }
    ],
    []
  );

  // are any files still training (prevent nav to search until files are settled)
  const isLoading = useMemo(() => rows.some((file) => !file.training?.successObj?.pages), [rows]);

  return (
    <>
      {areFilesBeingSubscribedTo ? (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="200px">
          <Typography variant="h3" sx={{ fontSize: PRIMARY_FONT_SIZE }}>
            Loading...
          </Typography>
        </Box>
      ) : filesData.length === 0 ? (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="200px">
          <Typography variant="h3" sx={{ fontSize: PRIMARY_FONT_SIZE }}>
            No files have been uploaded.
          </Typography>
        </Box>
      ) : (
        <Box width="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Typography variant="h3" sx={{ fontSize: PRIMARY_FONT_SIZE, mb: 1 }}>
            File Processing Status
          </Typography>
          <Box width="100%">
            <DataGrid
              showCellVerticalBorder={false}
              showColumnVerticalBorder={false}
              checkboxSelection={false}
              columns={columns}
              rows={rows}
              hideFooter={true}
              hideFooterRowCount={true}
              disableColumnMenu={true}
              autoHeight={false}
              sx={{ maxHeight: 350 }}
            />
          </Box>
          <Link to={`/search/p/${projectId}`}>
            <Button
              size="medium"
              disabled={isLoading}
              sx={{ mt: 4 }}
              variant="contained"
              endIcon={<NavigateNextIcon />}
            >
              Search
            </Button>
          </Link>
        </Box>
      )}
    </>
  );
});
