import { DataUpload, DataUploadEntry, SearchAndChat } from '@/features/knowledge-finder';
import { Page } from '../components/page';

export function KnowledgeFinderDataEntryPage() {
  return (
    <Page>
      <DataUploadEntry />
    </Page>
  );
}

export function KnowledgeFinderDataUploadPage() {
  return (
    <Page>
      <DataUpload />
    </Page>
  );
}

export function KnowledgeFinderSearch() {
  return (
    <Page>
      <SearchAndChat />
    </Page>
  );
}
