import { Page } from '@/components/page';
import { usePostApi } from '@/hooks/use-post-api';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface ProjectResponse {
  project: {
    id: string;
  };
  chat: {
    id: string;
  };
}

const Welcome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchContent, setSearchContent] = useState('');

  const { postData } = usePostApi<ProjectResponse>('project/with-report-builder');

  const handleSearch = async () => {
    if (!searchContent) return;
    const result = await postData({
      name: searchContent,
      firstMessage: searchContent
    });

    const searchParams = new URLSearchParams(location.search);

    navigate(`/demo/p/${result.project.id}/c/${result.chat.id}?${searchParams.toString()}`);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60vh'
      }}
    >
      <Typography variant="h2" align="center" gutterBottom mb={7}>
        What would you like to explore?
      </Typography>

      <TextField
        style={{ width: '100%' }}
        variant="outlined"
        size="medium"
        placeholder="neglected hypotheses in dementia and alzheimer's disease"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        value={searchContent}
        onChange={(e) => setSearchContent(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
    </div>
  );
};

export function DemoStart() {
  return (
    <Page>
      <Welcome />
    </Page>
  );
}
