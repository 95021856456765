import { useCallback, useEffect, useRef, useState } from 'react';
import { SessionStorageKey, getItemFromSessionStorage } from '@/stores/session-storage-utils';
import { SendMessageParams, useAgentChat } from '@/hooks/use-agent-chat';
import { Box, Card, IconButton, Input, List, ListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { pick } from 'lodash';
import MuiMarkdown, { getOverrides } from 'mui-markdown';
import { useParams, useSearchParams } from 'react-router-dom';
import { MarkdownSectionContent, SectionContentType } from '../vault-item';
import { AgentToInteractWith, ErrorMessage } from '@/features/chatbot';
import { Theme } from '@mui/system';
import { ArrowRightAlt } from '@mui/icons-material';

const htmlElements = ['p', 'h1', 'h2', 'h3', 'span', 'li', 'ol', 'ul', 'a'];

type InputFieldProps = {
  input: string;
  setInput: (value: string) => void;
  handleSendMessage: (message: string) => void;
  disabled?: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  theme: Theme;
};

const InputField = ({ input, setInput, handleSendMessage, disabled, inputRef, theme }: InputFieldProps) => (
  <Input
    inputRef={inputRef}
    disabled={disabled}
    disableUnderline={true}
    value={input}
    fullWidth
    onChange={(e) => setInput(e.target.value)}
    onKeyPress={(e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage(input);
      }
    }}
    multiline
    sx={{
      flex: 1,
      backgroundColor: theme.palette.grey[100],
      borderRadius: '25px 0 0 25px',
      border: 'none',
      outline: 'none',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: '1rem',
      '&:focus': {
        outline: 'none'
      }
    }}
  />
);

/**
 * n.b. relies on both the routing and session storage to supplement
 * its context for whether the chat should be with a single item or
 * a set of search results
 */
export const ChatWithVault = () => {
  const { loading, messages, error, sendMessage } = useAgentChat({
    initialMessages: [{ role: 'assistant', content: 'Hi, how can I help you today?' }],
    preserveInitialAssistantMessage: true
  });

  const [searchParams] = useSearchParams();
  const { page, tier, vaultName } = useParams();

  const [input, setInput] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleSendMessage = useCallback(
    async (message: string) => {
      let params: SendMessageParams;

      if (page && tier && searchParams.get('isSearchActive') !== 'true') {
        // on a single page, chat with just that page
        params = {
          agent: AgentToInteractWith.VaultPublicChatWithOneArticle,
          model: 'gpt-3.5-turbo-16k-0613',
          message,
          extras: {
            vaultId: vaultName
          },
          query: (
            JSON.parse(getItemFromSessionStorage(SessionStorageKey.VaultRecentItem) as string) as SectionContentType[]
          )
            .filter((section) => section.type === 'md' || section.type === 'chart')
            .map((section) => {
              if (section.type === 'md') {
                return (section as MarkdownSectionContent).content;
              } else if (section.type === 'chart') {
                // prevent too much info from preventing the ability to prompt w/ data context
                const chartHeader = section.data.length > 100 ? '### Chart Data Sample' : '### Chart Data';
                const chartData = section.data.length > 100 ? section.data.slice(0, 100) : section.data;

                return `${chartHeader}
                ${JSON.stringify(chartData)}
                ### Chart Configuration
                ${JSON.stringify(
                  pick(section.configuration, [
                    'type',
                    'props.colorField',
                    'props.xField',
                    'props.yField',
                    'props.xAxisTitle',
                    'props.yAxisTitle'
                  ])
                )}
                `;
              }
            })
            .join('\n')
        };
      } else {
        // viewing search results, chat with the search results
        const searchResults = JSON.parse(getItemFromSessionStorage(SessionStorageKey.VaultSearchResults) as string);
        params = {
          agent: AgentToInteractWith.VaultPublicChatWithSearchResults,
          model: 'gpt-3.5-turbo-16k-0613',
          message,
          extras: {
            vaultId: vaultName,
            searchResults: JSON.stringify(searchResults.results)
          },
          query: JSON.stringify(searchResults.query)
        };
      }

      // Ignore empty messages or if the state isn't resolved fully
      if (message.trim() === '' || loading || !params.query) return;

      if (!loading) {
        setInput('');
        await sendMessage(params);
        // Refocus the input after sending
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }
    },
    [inputRef, loading, sendMessage, page, tier, vaultName, searchParams]
  );

  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };
  useEffect(scrollToBottom, [messages]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 150px)' }}>
      {/* Messages container */}
      <Box
        sx={{
          flexGrow: 1, // Makes this box take up all available space.
          overflowY: 'auto',
          marginBottom: theme.spacing(3), // allow the scroll to get all the way to the bottom each time
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          },
          msOverflowStyle: 'none'
        }}
      >
        <List>
          {messages.map((msg, index) => (
            <ListItem
              key={index}
              sx={{
                justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start'
              }}
            >
              <Card
                variant="outlined"
                sx={{
                  p: theme.spacing(1),
                  maxWidth: '90%',
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  backgroundColor: msg.role === 'user' ? theme.palette.blue.light : theme.palette.red.light
                }}
              >
                <Typography
                  sx={{
                    color: msg.role === 'user' ? theme.palette.blue.dark : theme.palette.red.dark
                  }}
                  variant={'body2'}
                >
                  <MuiMarkdown
                    overrides={{
                      ...getOverrides({}),
                      ...htmlElements.reduce(
                        (acc, element) => ({
                          ...acc,
                          [element]: {
                            component: element,
                            props: {
                              style: theme.typography.body2
                            }
                          }
                        }),
                        {}
                      )
                    }}
                  >
                    {msg.role === 'assistant' ? `✦&nbsp;&nbsp;${msg.content}` : msg.content}
                  </MuiMarkdown>
                </Typography>
              </Card>
            </ListItem>
          ))}
        </List>
        <ErrorMessage error={error} />
        <div ref={messagesEndRef} />
      </Box>

      {/* Chat input container */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: theme.palette.background.default,
          position: 'sticky', // stick to the bottom of the chat
          bottom: 0,
          zIndex: 10, // always overlay above any text flowing in
          padding: isSmallScreen ? theme.spacing(2) : theme.spacing(0),
          paddingTop: isSmallScreen ? theme.spacing(1) : theme.spacing(0),
          paddingBottom: isSmallScreen ? theme.spacing(2) : theme.spacing(3)
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.grey[100],
            maxWidth: '1100px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            borderRadius: '25px',
            overflow: 'hidden',
            m: 1
          }}
        >
          <InputField
            inputRef={inputRef}
            input={input}
            setInput={setInput}
            handleSendMessage={handleSendMessage}
            disabled={loading}
            theme={theme}
          />
          <IconButton
            sx={{ color: theme.palette.grey[500], paddingRight: theme.spacing(2) }}
            aria-label="send message"
            onClick={() => handleSendMessage(input)}
          >
            <ArrowRightAlt />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};
