/**
 * Format string array as a sentence
 * - comma separate all items and "and" the last item
 */
export const createSentenceFromArray = (arr: string[]) => {
  if (arr.length === 1) {
    return arr[0];
  } else if (arr.length === 2) {
    return arr.join(' and ');
  }
  return arr.slice(0, arr.length - 1).join(', ') + ', and ' + arr.slice(-1);
};
