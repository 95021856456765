import { Bar, Pie } from './demo-charts';
import { Scatter } from '@prism-analytic-technologies/prism-charts'; // need to fix this

// todo: move this file out of /features when we start pulling charts in from prism-charts
// map names to the components
export const CHART_COMPONENTS_MAP = {
  SCATTER: Scatter,
  BAR: Bar,
  PIE: Pie
};

// map chart types to their props and data fields
export const CHART_PROPS_MAP = {
  SCATTER: {
    requiredProps: {
      // setting up like this so we can extend and map to any component types
      // ex. if we expose something like 'hasInteractiveLegends' in the UI, we can
      // map it to a radio or a check box since it's a boolean
      xField: { type: 'SELECT', options: 'DATA_FIELDS', showAggregate: true },
      yField: { type: 'SELECT', options: 'DATA_FIELDS', showAggregate: true }
    },
    optionalProps: {
      colorField: {
        type: 'SELECT',
        options: 'DATA_FIELDS',
        showAggregate: true
      },
      colorScheme: { type: 'STRING' },
      colorAxisTitle: { type: 'STRING' },
      sizeField: {
        type: 'SELECT',
        options: 'DATA_FIELDS',
        showAggregate: true
      },
      sizeAxisTitle: { type: 'STRING' },
      xAxisTitle: { type: 'STRING' },
      xFieldAggregate: {
        type: 'SELECT',
        options: 'AGG_OPTIONS',
        showAggregate: true
      },
      yAxisTitle: { type: 'STRING' },
      yFieldAggregate: {
        type: 'SELECT',
        options: 'AGG_OPTIONS',
        showAggregate: true
      }
    }
  },
  BAR: {
    requiredProps: {
      xField: { type: 'SELECT', options: 'DATA_FIELDS' },
      yField: { type: 'SELECT', options: 'DATA_FIELDS', showAggregate: true }
    },
    optionalProps: {
      colorField: {
        type: 'SELECT',
        options: 'DATA_FIELDS',
        showAggregate: true
      },
      colorScheme: { type: 'STRING' },
      colorAxisTitle: { type: 'STRING' },
      xAxisTitle: { type: 'STRING' },
      xFieldAggregate: {
        type: 'SELECT',
        options: 'AGG_OPTIONS',
        showAggregate: true
      },
      yAxisTitle: { type: 'STRING' },
      yFieldAggregate: {
        type: 'SELECT',
        options: 'AGG_OPTIONS',
        showAggregate: true
      }
    }
  },
  PIE: {
    requiredProps: {
      colorField: { type: 'SELECT', options: 'DATA_FIELDS' },
      sizeField: {
        type: 'SELECT',
        options: 'DATA_FIELDS',
        showAggregate: true
      }
    },
    optionalProps: {
      colorScheme: { type: 'STRING' },
      colorAxisTitle: { type: 'STRING' },
      sizeAxisTitle: { type: 'STRING' }
    }
  }
};

export const AGG_OPTIONS = ['sum', 'count'];
