// firebaseConfig.ts
const firebaseConfig = {
  apiKey: import.meta.env.VITE_PRISM_FB_API_KEY || 'AIzaSyA9KIrCIAiJUIbo90POyL4Y7TdordB-0Bw',
  authDomain: import.meta.env.VITE_PRISM_FB_AUTH_DOMAIN || 'prism-bio-demo.firebaseapp.com',
  projectId: import.meta.env.VITE_PRISM_FB_PROJECT_ID || 'prism-bio-demo',
  storageBucket: import.meta.env.VITE_PRISM_FB_STORAGE_BUCKET || 'prism-bio-demo.appspot.com',
  messagingSenderId: import.meta.env.VITE_PRISM_FB_MESSAGING_SENDER_ID || '1019657578891',
  appId: import.meta.env.VITE_PRISM_FB_APP_IE || '1:1019657578891:web:1e93b226891e3adccaa78e'
};

export default firebaseConfig;
