import { Box, Button, Grid } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from 'react-router-dom';
import { ExhibitTitle } from './exhibit-title';
import { ExhibitViewBox } from './exhibit-view-box';
import { ExhibitMetadata } from './exhibit-metadata';
import { ChartBuilder } from './chart-builder';

type ExhibitBuilderProps = {
  title: string;
  exhibitTitle: string;
  metaDataFields: string[];
};

export const ExhibitBuilder = ({ title, exhibitTitle, metaDataFields }: ExhibitBuilderProps) => {
  return (
    <Box>
      <ExhibitTitle title={title} />
      <Grid container>
        <Grid item md={1} sm={1} xs={12}>
          <Link to="/">
            <Button sx={{ mt: 20, minWidth: 0, ml: -5 }}>
              <ArrowBackIosIcon fontSize="large" />
            </Button>
          </Link>
        </Grid>
        <Grid item md={10} sm={10} xs={12}>
          <ExhibitViewBox title={exhibitTitle.toUpperCase()}>
            <ChartBuilder />
            <hr />
            <ExhibitMetadata fields={metaDataFields} />
            <Box display="flex" justifyContent="center" sx={{ mt: 10 }}>
              <Button variant="contained">Save</Button>
            </Box>
          </ExhibitViewBox>
        </Grid>
        <Grid item md={1} sm={1} xs={12} />
      </Grid>
    </Box>
  );
};
