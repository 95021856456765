import theme from '@/styles/theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { StyledTextField } from '../vault-layout';

export const VaultSearchBox = (): JSX.Element => {
  // get current search params (to show preserved search in text box on refresh)
  const [searchParams] = useSearchParams();
  const isShowingSearch = searchParams.get('isSearchActive') === 'true';
  const initialSearchQuery = searchParams.get('search') || null;
  const [input, setInput] = useState(initialSearchQuery);

  // stores current query for displaying helper text
  const [searchQuery, setSearchQuery] = useState(input);
  const [helperText, setHelperText] = useState(searchQuery && input ? `Showing results for "${searchQuery}"` : '');

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setInput(initialSearchQuery || '');
  }, [initialSearchQuery]);

  useEffect(() => {
    if (!isShowingSearch) {
      setHelperText('');
    } else {
      setHelperText(`Showing results for "${searchQuery}"`);
    }
  }, [isShowingSearch, searchQuery]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" width="100%">
      {initialSearchQuery && searchQuery && !isShowingSearch && (
        <Tooltip
          title="Back to results"
          componentsProps={{
            tooltip: {
              sx: {
                fontFamily: 'Aeonik',
                fontSize: 10
              }
            }
          }}
        >
          <IconButton
            sx={{
              color: theme.palette.blue.dark,
              filter: 'drop-shadow(0px 0px 74px rgba(0, 50, 98, 0.22))',
              height: 30,
              width: 30,
              mt: 2,
              mr: 1,
              backgroundColor: theme.palette.background.default
            }}
            onClick={() => {
              const searchParams = input === '' ? '' : `?search=${input}&isSearchActive=${true}`;
              navigate(`${pathname}${searchParams}`);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      )}
      <StyledTextField
        fullWidth
        size="small"
        sx={{
          marginLeft: searchQuery && !isShowingSearch ? 1 : 0, // provide some space if the back arrow is present
          width: 'auto', // let it adjust based on content and available space
          minWidth: {
            xs: '80%',
            sm: '85%',
            md: '90%',
            lg: '95%'
          },
          maxWidth: {
            xs: '90%'
          }
        }}
        label="Search the Vault"
        variant="standard"
        helperText={helperText}
        onChange={(e) => setInput(e.target.value)}
        value={input}
        onKeyPress={async (e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            setSearchQuery(input);
            const searchParams = input === '' ? '' : `?search=${input}&isSearchActive=${true}`;
            navigate(`${pathname}${searchParams}`);
          }
        }}
        FormHelperTextProps={{
          sx: {
            color: 'gray',
            fontWeight: 100,
            fontStyle: 'italic'
          }
        }}
      />
    </Box>
  );
};
