import { AppBar, Box, Grid, useMediaQuery } from '@mui/material';
import theme from '@/styles/theme';
import { VaultSideNav } from './side-nav/side-nav';
import { VaultLayout } from './vault-layout';
import { useEffect } from 'react';
import { usePostApiWithoutAuth } from '@/hooks/use-post-api-without-auth';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavBar, useNavBarMenu } from '@/features/navbar';
import { Theme } from '@mui/system';

// complete vault component
export const VaultHome = (): JSX.Element => {
  const { isAuthenticated, user } = useAuth0();
  const { vaultName = '' } = useParams();
  const { setTitle } = useNavBar();
  const { setContent } = useNavBarMenu();
  const { postData } = usePostApiWithoutAuth<void>('vault/public/warm-up');

  // on entry to a vault home, ping the search API to warm up for faster semantic search
  useEffect(() => {
    postData({
      vaultId: vaultName,
      // if user ID present, include it
      ...(isAuthenticated && { userId: user?.sub })
    });
  }, [isAuthenticated, postData, user, vaultName]);

  useEffect(() => {
    if (vaultName === 'dht') {
      setTitle('Vault: Ontology of Digital Health Technologies');
    }
  }, [setTitle, vaultName]);

  useEffect(() => {
    setContent(<VaultSideNav />);
  }, [setContent]);

  const isMdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Box>
      <AppBar sx={{ backgroundColor: theme.palette.blue.main, height: '100%', zIndex: -1 }} elevation={0}></AppBar>
      <Box
        sx={{
          position: 'fixed',
          top: 70,
          bottom: 0,
          left: 0,
          right: 0,
          borderTopLeftRadius: theme.borderRadius,
          borderTopRightRadius: 45,
          overflow: 'hidden',
          background: '#F8F8F8'
        }}
      >
        {isMdScreen ? (
          <VaultLayout />
        ) : (
          <Grid columnSpacing={5} container sx={{ height: '100%' }}>
            <Grid item xs={3} sm={3} md={3} lg={3}>
              <VaultSideNav />
            </Grid>
            <Grid item xs={9} sm={9} md={9} lg={9}>
              <VaultLayout />
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};
