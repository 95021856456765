import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

interface AuthBoundaryProps {
  children: React.ReactNode;
}

export const AuthBoundary = ({ children }: AuthBoundaryProps) => {
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && user) {
      // Set the user context in Sentry
      Sentry.setUser({
        uid: user.sub
      });
    } else {
      // Clear the user context when the user is not authenticated
      Sentry.setUser(null);
    }
  }, [isAuthenticated, user]);

  return <>{children}</>;
};

export default AuthBoundary;
