/**
 * Temporary dummy datasets to demo exhibit builder functionality
 */

export type Data = {
  methods: string;
  description: string;
  name: string;
  values: Record<string, unknown>[];
};

export const dataset1 = {
  methods: 'This dataset was originally created for scatter plot storybook stories in the original prism app.',
  description: 'first dummy dataset',
  name: 'dataset1',
  values: [
    { xField: 'A', y: 'test', size: 5, color: 'color1', shape: 'shape1' },
    { xField: 'B', y: 'test1', size: 2, color: 'color2', shape: 'shape2' },
    { xField: 'C', y: 'test2', size: 12, color: 'color3', shape: 'shape3' },
    { xField: 'D', y: 'test3', size: 5, color: 'color4', shape: 'shape1' },
    { xField: 'E', y: 'test1', size: 60, color: 'color', shape: 'shape3' },
    { xField: 'F', y: 'test2', size: 100, color: 'color1', shape: 'shape4' },
    { xField: 'G', y: 'test3', size: 11, color: 'color2', shape: 'shape5' },
    { xField: 'H', y: 'test1', size: 2, color: 'color3', shape: 'shape2' },
    { xField: 'I', y: 'test2', size: 32, color: 'color4', shape: 'shape1' },
    { xField: 'A', y: 'test4', size: 45, color: 'color', shape: 'shape1' },
    { xField: 'B', y: 'test1', size: 2, color: 'color2', shape: 'shape3' },
    { xField: 'C', y: 'test', size: 90, color: 'color1', shape: 'shape4' },
    { xField: 'D', y: 'test1', size: 28, color: 'color3', shape: 'shape5' },
    { xField: 'E', y: 'test2', size: 20, color: 'color', shape: 'shape2' }
  ]
};

export const dataset2 = {
  methods:
    'This is a temporary dataset for testing new features. It has temporal, quantitative, and nominal data. It was generated randomly',
  description: 'second dummy dataset',
  name: 'dataset2',
  values: [
    { start_date: '01-12-1989', y: 'test', size: 5, color: 'color1', count: 4 },
    {
      start_date: '02-09-1998',
      y: 'test1',
      size: 2,
      color: 'color2',
      count: 1
    },
    {
      start_date: '03-06-1996',
      y: 'test2',
      size: 12,
      color: 'color3',
      count: 8
    },
    {
      start_date: '04-09-2005',
      y: 'test3',
      size: 5,
      color: 'color4',
      count: 4
    },
    {
      start_date: '04-01-2010',
      y: 'test1',
      size: 60,
      color: 'color',
      count: 3
    },
    {
      start_date: '05-16-2018',
      y: 'test2',
      size: 100,
      color: 'color1',
      count: 2
    },
    {
      start_date: '06-23-2019',
      y: 'test3',
      size: 11,
      color: 'color2',
      count: 2.5
    },
    {
      start_date: '07-19-2021',
      y: 'test1',
      size: 2,
      color: 'color3',
      count: 7
    },
    {
      start_date: '08-25-2007',
      y: 'test2',
      size: 32,
      color: 'color4',
      count: 7.6
    },
    {
      start_date: '09-06-1990',
      y: 'test4',
      size: 45,
      color: 'color',
      count: 5
    },
    {
      start_date: '10-10-2010',
      y: 'test1',
      size: 2,
      color: 'color2',
      count: 10
    },
    {
      start_date: '11-28-2004',
      y: 'test',
      size: 90,
      color: 'color1',
      count: 3
    },
    {
      start_date: '12-17-2017',
      y: 'test1',
      size: 28,
      color: 'color3',
      count: 6
    },
    {
      start_date: '12-03-2023',
      y: 'test2',
      size: 20,
      color: 'color',
      count: 4
    }
  ]
};

export const dataset3 = {
  methods:
    "This is a dummy dataset for showing charts in the mMVP. This data is flat and hasn't been pre-aggregated or cleaned.",
  description: 'third dummy dataset',
  name: 'dataset3',
  values: [
    { x: 0.04, y: 5, color: 'color1', shape: 'shape1' },
    { x: 0.06, y: 2, color: 'color2', shape: 'shape2' },
    { x: 0.09, y: 12, color: 'color3', shape: 'shape3' },
    { x: 0.11, y: 5, color: 'color4', shape: 'shape1' },
    { x: 0.15, y: 60, color: 'color', shape: 'shape3' },
    { x: 0.18, y: 100, color: 'color1', shape: 'shape4' },
    { x: 0.2, y: 1000, color: 'color2', shape: 'shape5' },
    { x: 0.21, y: 1267, color: 'color3', shape: 'shape2' },
    { x: 0.29, y: 345, color: 'color4', shape: 'shape1' },
    { x: 0.33, y: 934, color: 'color', shape: 'shape1' },
    { x: 0.49, y: 2, color: 'color2', shape: 'shape3' },
    { x: 0.67, y: 90, color: 'color1', shape: 'shape4' },
    { x: 0.68, y: 655, color: 'color3', shape: 'shape5' },
    { x: 0.68, y: 721, color: 'color', shape: 'shape2' },
    { x: 0.7, y: 187, color: 'color1', shape: 'shape1' },
    { x: 0.72, y: 379, color: 'color2', shape: 'shape2' },
    { x: 0.75, y: 509, color: 'color3', shape: 'shape3' },
    { x: 0.78, y: 798, color: 'color4', shape: 'shape1' },
    { x: 0.795, y: 841, color: 'color', shape: 'shape3' },
    { x: 0.8, y: 100, color: 'color1', shape: 'shape4' },
    { x: 0.88, y: 11, color: 'color2', shape: 'shape5' },
    { x: 0.84, y: 2, color: 'color3', shape: 'shape2' },
    { x: 0.82, y: 32, color: 'color4', shape: 'shape1' },
    { x: 0.98, y: 45, color: 'color', shape: 'shape1' },
    { x: 0.92, y: 2, color: 'color2', shape: 'shape3' },
    { x: 0.02, y: 82, color: 'color1', shape: 'shape4' },
    { x: 0.46, y: 28, color: 'color3', shape: 'shape5' },
    { x: 0.08, y: 20, color: 'color', shape: 'shape2' }
  ]
};

export const datasetNames: { [key: string]: Data } = {
  dataset1: dataset1,
  dataset2: dataset2,
  dataset3: dataset3
};
