import { Box, Paper } from '@mui/material';
import { useTheme } from '@mui/system';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useFileUpload } from '../../../hooks/use-file-upload';
import { SendMessageParams, useAgentChat } from '../../../hooks/use-agent-chat';
import { ErrorMessage } from './error-message';
import { FileCard } from './file-card';
import { SendButton, UploadButton } from './input-buttons';
import { InputField } from './input-field';
import { MessageList } from './message-list';
import { AgentToInteractWith, ChatGPTMessage } from '../api/prism-agent';
import { FileWithPreview } from '@/types/files';

const Chatbot = ({
  initialMessages,
  projectId,
  chatId
}: {
  initialMessages: ChatGPTMessage[];
  projectId: string;
  chatId: string;
}) => {
  const { loading, messages, error, sendMessage } = useAgentChat({
    initialMessages: initialMessages ?? [],
    projectId,
    chatId
  });
  const initialized = useRef(false);
  const [input, setInput] = useState('');
  const theme = useTheme();

  const { files, handleFileRemove, getRootProps, getInputProps, setFiles } = useFileUpload(projectId!, chatId!);

  const handleSendMessage = useCallback(
    async (message: string) => {
      const params: SendMessageParams = {
        agent: AgentToInteractWith.Interviewer,
        message,
        files: files || [],
        setFiles
      };
      // Ignore empty messages
      if (message.trim() === '' || loading) return;

      if (!loading) {
        setInput('');
        // disable the input field while the message is being sent
        await sendMessage(params); // todo only get rid of files on upload success
      }
    },
    [files, loading, sendMessage, setFiles]
  );

  // Send first message when component mounts
  useEffect(() => {
    if (initialized.current) return;
    if (initialMessages?.length === 1) {
      handleSendMessage(initialMessages?.[0]?.content);
    }
    initialized.current = true;
  }, [projectId, handleSendMessage, initialMessages]);

  // Scroll to bottom when messages change
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(scrollToBottom, [messages]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box
        sx={{
          overflowY: 'auto',
          marginBottom: theme.spacing(3)
        }}
      >
        <MessageList messages={messages} />
        <ErrorMessage error={error} />
        <div ref={messagesEndRef} />
      </Box>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: theme.spacing(2),
          backgroundColor: theme.palette.background.default
        }}
      >
        <Paper
          sx={{
            flexGrow: 1,
            maxWidth: '1000px',
            padding: theme.spacing(3),
            paddingTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column'
          }}
          variant="outlined"
        >
          <InputField input={input} setInput={setInput} handleSendMessage={handleSendMessage} disabled={loading} />
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {files.map((file: FileWithPreview, index: number) => (
              <FileCard handleFileRemove={handleFileRemove} file={file} key={file.preview + index} />
            ))}
          </Box>
        </Paper>

        <SendButton handleSendMessage={handleSendMessage} input={input} />
        <UploadButton getInputProps={getInputProps} getRootProps={getRootProps} />
      </Box>
    </Box>
  );
};

export default Chatbot;
