import { Alert, Box, Divider, Grid, Typography } from '@mui/material';
import { Project } from '../../../types/projects';
import { AddProjectButton } from './add-project-button';
import { ProjectCard } from './project-card';

const BaseProjectLayout = ({
  projects,
  title,
  noProjectsAlert,
  showAddButton
}: {
  projects?: Project[];
  title?: string;
  noProjectsAlert?: string;
  showAddButton?: boolean;
}) => {
  const gridSizing = { xs: 12, sm: 12, md: 6, lg: 6 };
  return (
    <Box sx={{ mt: 3 }}>
      <Typography variant="h3">{title}</Typography>
      <Box sx={{ mt: 4, mb: 5 }}>
        <Grid container rowSpacing={3} columnSpacing={4}>
          {(!projects || projects.length < 1) && noProjectsAlert && (
            <>
              <Grid item {...gridSizing}>
                <Alert severity="info">
                  <Typography variant="caption">{noProjectsAlert}</Typography>
                </Alert>
              </Grid>
              <Grid item {...gridSizing} />
            </>
          )}
          {showAddButton && (
            <Grid item {...gridSizing}>
              <Box>
                <AddProjectButton />
              </Box>
            </Grid>
          )}
          {projects &&
            projects.length >= 1 &&
            projects.map((project) => (
              <Grid item {...gridSizing}>
                <ProjectCard imageSrc={project.image} url={project.url} title={project.title} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export const ProjectLayout = ({ projects, sharedProjects }: { projects?: Project[]; sharedProjects?: Project[] }) => {
  return (
    <>
      <BaseProjectLayout
        showAddButton
        projects={projects}
        title="My Reports"
        noProjectsAlert="You haven't created any projects yet. Click the button below to get started!"
      />
      {(projects ?? []).length >= 1 && (sharedProjects ?? []).length >= 1 && <Divider />}
      <BaseProjectLayout
        projects={sharedProjects}
        title={(sharedProjects ?? []).length >= 1 ? 'Shared With Me' : undefined}
      />
    </>
  );
};
