import { memo, useCallback } from 'react';
import { Box, Toolbar } from '@mui/material';
import { DataGridPro as MuiDataGrid, GridRow, LicenseInfo } from '@mui/x-data-grid-pro';
import { DataGridProps } from '@/types/data-grid';

// using pro version of mui grid
LicenseInfo.setLicenseKey(
  'a38ff3f06d8a6f6ae09781983f502384Tz01OTEzOSxFPTE3MDcwNjcxNzkxMjMsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

/**
 * Read-only data grid
 *
 * Supports a variety of renderers, such as chips and links.
 */
export const DataGrid = ({ columns, getRowDetail, rows, sx, ...props }: DataGridProps): JSX.Element => {
  // memoized for perf (https://mui.com/x/react-data-grid/performance/)
  const MemoizedRow = memo(GridRow);

  const addId = !rows[0]?.id;

  const getDetailPanelContent = useCallback(
    ({ row }: { row: Record<string, unknown> }) => (getRowDetail ? getRowDetail({ row }) : null),
    [getRowDetail]
  );

  return (
    <Box sx={sx}>
      <MuiDataGrid
        checkboxSelection
        components={{
          Toolbar: () => <Toolbar />
        }}
        sx={{
          typography: 'body1',
          '& .MuiDataGrid-columnHeader': {
            typography: 'caption'
          }
        }}
        density="compact"
        columns={columns}
        getDetailPanelContent={getRowDetail ? getDetailPanelContent : undefined}
        getDetailPanelHeight={() => 'auto'} // Height based on the content.
        rows={addId ? rows.map((row, index) => ({ ...row, id: index })) : rows}
        slots={{
          row: MemoizedRow
        }}
        {...props}
      />
    </Box>
  );
};
