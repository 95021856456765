import { Typography } from '@mui/material';

export const Header = () => (
  <Typography
    sx={{
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      mt: 5,
      mb: 5
    }}
    variant="h3"
  >
    New Data Conversation
  </Typography>
);
