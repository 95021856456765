import { Page } from '../components/page';
import { ProjectLayout } from '../features/project-layout/components/project-layout';
import { Project } from '../types/projects';

export default function Home({ projects, sharedProjects }: { projects?: Project[]; sharedProjects?: Project[] }) {
  return (
    <Page>
      <ProjectLayout projects={projects} sharedProjects={sharedProjects} />
    </Page>
  );
}
