import { Select, SelectOption } from '@/components';
import { Grid } from '@mui/material';
import { AGG_OPTIONS, CHART_COMPONENTS_MAP, CHART_PROPS_MAP } from '../../constants';

type RequiredPropsType = Record<string, { showAggregate: boolean }>;

// component for selecting given chart selections
export const RequiredPropsSelections = ({
  fieldOptions,
  updateProps,
  currentChartType
}: {
  fieldOptions: SelectOption[];
  updateProps: (item: Record<string, unknown>) => void;
  currentChartType: keyof typeof CHART_COMPONENTS_MAP;
}): JSX.Element => {
  const requiredProps = CHART_PROPS_MAP[currentChartType].requiredProps as RequiredPropsType;

  return (
    <>
      {Object.keys(requiredProps).map((prop, index) => {
        const fieldInfo = requiredProps[prop];
        return (
          <Grid container sx={{ mb: 2 }} columnSpacing={2} key={`${prop}-${index}-container`}>
            <Grid item xs={12} sm={12} md={fieldInfo.showAggregate ? 6 : 12} key={`${prop}-${index}-item`}>
              <Select
                required
                fullWidth
                label={prop}
                placeholderText={`Choose data column for ${prop}`}
                size="small"
                key={prop}
                options={fieldOptions}
                onChange={(option: SelectOption) => updateProps({ [prop]: option.value })}
              />
            </Grid>
            {fieldInfo.showAggregate && (
              // show aggregation dropdown if sensible
              <Grid item xs={12} sm={12} md={6}>
                <Select
                  fullWidth
                  label="Agg."
                  showNoneOption
                  placeholderText={`Aggregate ${prop}`}
                  size="small"
                  key={`${prop}-agg`}
                  options={AGG_OPTIONS.map((agg) => ({
                    value: agg,
                    label: agg
                  }))}
                  onChange={(option: SelectOption) =>
                    updateProps({
                      [`${prop}Aggregate`]: option.value
                    })
                  }
                />
              </Grid>
            )}
          </Grid>
        );
      })}
    </>
  );
};
