import { Typography } from '@mui/material';
import { Page } from '../components/page';

export default function GenerateProject() {
  return (
    <Page>
      <Typography variant="h3">Search to generate a new project</Typography>
    </Page>
  );
}
