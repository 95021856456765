import { createContext } from 'react';

interface ErrorContextType {
  error: { type: string; message?: string } | null;
  setError: React.Dispatch<React.SetStateAction<{ type: string; message?: string } | null>>;
}

export const ErrorContext = createContext<ErrorContextType>({
  error: null,
  setError: () => {
    throw new Error('setError function not implemented');
  }
});
