import axios from 'axios';
import { useCallback, useState } from 'react';
import { useAuthToken } from './use-auth-token';

type ReturnType<T> = { postData: (data: object) => Promise<T>; data: T | null; error: Error | null | unknown };

export const usePostApi = <T,>(endpoint: string): ReturnType<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null | unknown>(null);

  const fetchToken = useAuthToken();

  const postData = useCallback(
    async (body: object) => {
      try {
        const token = await fetchToken();
        const response = await axios
          .create()
          .post(
            `${
              import.meta.env.VITE_PRISM_BACKEND_ROOT_ENDPOINT || 'https://backend-fftuh3xouq-uc.a.run.app'
            }/${endpoint}`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );

        setData(response.data);
        return response.data;
      } catch (err) {
        setError(err);
      }
    },
    [endpoint, fetchToken]
  );

  return { postData, data, error };
};
