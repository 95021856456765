import { Box, Theme, useMediaQuery } from '@mui/material';
import { ChartSectionContent } from '../vault-item';
import { Pie, Scatter, Bar, Network, Node } from '@prism-analytic-technologies/prism-charts';
import { useNavigate } from 'react-router-dom';

// todo: better typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CHART_MAP: { [key: string]: (props: any) => JSX.Element } = {
  Pie: Pie,
  Scatter: Scatter,
  Bar: Bar,
  Network: Network
};

export const RenderedChart = ({ chartSection }: { chartSection: ChartSectionContent }): JSX.Element => {
  const { configuration, data } = chartSection;
  const Component = CHART_MAP[configuration?.type || ''];
  const isMdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  // conditional props to wrap the color legend on the scatter plot for md and smaller screens
  const conditionalScatterProps =
    configuration?.type === 'Scatter' && isMdScreen ? { colorLegendLocation: 'top', colorLegendColumns: 2 } : {};

  const navigate = useNavigate();

  const networkProps =
    configuration?.type === 'Network'
      ? {
          onNodeClick: (_event: Event, node: Node) => {
            navigate(`/vaults/dht/${node?.link}`);
          }
        }
      : {};

  return (
    <Box
      sx={{
        minWidth: {
          sm: '300px' // at least 300px on small screens & larger
        }
      }}
    >
      {Component && (
        <Component
          data={data}
          axisLabelLimit={isMdScreen ? 75 : undefined} // truncate the y axis tick labels on md or smaller screens
          showAxisLabelTooltip={isMdScreen} // show a tooltip on tick labels when they're truncated
          {...conditionalScatterProps}
          {...networkProps}
          {...configuration?.props}
          width="container"
        />
      )}
    </Box>
  );
};
