import { Typography } from '@mui/material';

/**
 * Title for Exhibit
 */
export const ExhibitTitle = ({ title }: { title: string }): JSX.Element => (
  <Typography
    sx={{
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      mt: 5,
      mb: 5
    }}
    variant="h3"
  >
    {title}
  </Typography>
);
