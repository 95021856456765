import theme from '@/styles/theme';
import { Box, Card, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { FileSimilaritySearchResult } from './results';

import { ChatWithSearchResults } from './chat-with-search-results';
import './style.css';

/**
 * Chat wrapped in box that sticks on scroll
 */
export const StickyChat = ({
  query,
  isChatting,
  results,
  projectId,
  onChatClosed
}: {
  query: string;
  isChatting: boolean;
  results: FileSimilaritySearchResult[];
  projectId: string;
  onChatClosed: () => void;
}): JSX.Element => {
  return (
    <Box
      className={isChatting ? 'active' : 'inactive'}
      sx={{
        width: isChatting ? '33%' : 0,
        transition: 'width 1200ms',
        visibility: isChatting ? 'visible' : 'hidden'
      }}
    >
      <Card
        variant="outlined"
        sx={{
          backgroundColor: theme.palette.background.default,
          height: '100%',
          minHeight: '70vh',
          maxHeight: 650,
          overflow: 'scroll',
          mb: 2,
          mt: 2,
          mr: -10,
          top: 80,
          position: 'sticky',
          scrollbarWidth: 'none', // Hide the scrollbar for firefox
          '&::-webkit-scrollbar': {
            display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
          },
          '&-ms-overflow-style:': {
            display: 'none' // Hide the scrollbar for IE
          },
          border: `1px solid ${theme.palette.divider}`
        }}
      >
        <IconButton
          sx={{
            display: 'flex',
            position: 'absolute',
            top: 0,
            right: 0,
            float: 'right',
            zIndex: 2
          }}
          onClick={onChatClosed}
        >
          <ClearIcon />
        </IconButton>
        <ChatWithSearchResults results={results || []} query={query} projectId={projectId} />
      </Card>
    </Box>
  );
};
