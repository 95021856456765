import { Box, Typography, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { usePostApi } from '@/hooks/use-post-api';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

interface ProjectResponse {
  id: string;
}

export const DataUploadEntry = () => {
  const [isCreating, setIsCreating] = useState(false);

  const navigate = useNavigate();

  const { postData } = usePostApi<ProjectResponse>('project/with-data-chat');

  const handleClick = async () => {
    try {
      setIsCreating(true);
      const project = await postData({
        // TODO: when truly hooked up to an entry flow make name more configurable
        name: 'chat with data'
      });
      navigate(`/chat-with-data/p/${project.id}`);
    } catch (error) {
      console.error('Failed to make async request:', error);
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Align items horizontally center
        paddingTop: '2rem' // Add some padding at the top
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          mt: 5,
          mb: 5
        }}
        variant="h3"
      >
        Chat With Data Projects
      </Typography>
      {isCreating ? (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="200px">
          <Typography variant="h3" sx={{ fontSize: '1.7rem' }}>
            Creating...
          </Typography>
        </Box>
      ) : (
        <Button
          onClick={handleClick}
          disabled={isCreating}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100px',
            width: '200px',
            mt: 5,
            mb: 5,
            background: '#E0E0E0', // Light grey background
            border: '2px solid #BDBDBD', // Darker grey border
            borderRadius: '8px', // Rounded corners
            color: '#757575', // Even darker grey text/icon color
            '&:hover': {
              background: '#D5D5D5' // Slightly darker grey on hover
            }
          }}
        >
          <AddIcon htmlColor="#757575" fontSize="large" />
        </Button>
      )}
    </Box>
  );
};
