import { Box } from '@mui/material';
import { SetStateAction, useMemo, useState } from 'react';

import { CHART_COMPONENTS_MAP, CHART_PROPS_MAP } from '../../constants';
import { datasetNames } from '../../demo-data';
import { ChartPreviewWithAudit } from './preview';

import { Select } from '@/components';
import { ChartPropsSelections } from './all-props-selection';

/**
 * Chart Builder and Preview
 * - component for choosing data, chart type, and chart props
 */
export const ChartBuilder = (): JSX.Element => {
  const [currentDataset, setCurrentDataset] = useState<Record<string, unknown>[]>([]);
  const [datasetName, setDatasetName] = useState('');
  const [currentChartType, setCurrentChartType] = useState<keyof typeof CHART_COMPONENTS_MAP | ''>('');

  // options for data fields
  const fieldOptions =
    currentDataset.length >= 1
      ? Object.keys(currentDataset[0]).map((field) => ({
          label: field,
          value: field
        }))
      : [];

  // store available chart types
  const chartTypes = Object.keys(CHART_COMPONENTS_MAP);

  // chart props
  const [chartProps, setChartProps] = useState({});

  // optional props for the selected chart
  // shows the user what can be entered into "Params" text field
  // todo: use optional props for form validation of open text field
  const optionalProps = useMemo(
    () => (currentChartType ? CHART_PROPS_MAP[currentChartType].optionalProps : {}),
    [currentChartType]
  );

  // component for chart
  const Chart = CHART_COMPONENTS_MAP[currentChartType || 'SCATTER'];

  return (
    <Box>
      <Select
        sx={{ mb: 2 }}
        required
        placeholderText="Choose Dataset"
        label="Data"
        fullWidth
        size="small"
        value={datasetName}
        onChange={({ value }) => {
          setDatasetName(value as SetStateAction<string>);
          setCurrentDataset(datasetNames[value].values);
          setChartProps({ ...chartProps, data: datasetNames[value].values });
        }}
        options={Object.keys(datasetNames).map((key) => ({
          label: key,
          value: key
        }))}
      />
      <Select
        required
        fullWidth
        label="Chart"
        placeholderText="Choose Chart Type"
        disabled={currentDataset.length < 1}
        disabledText="Must select a dataset first."
        size="small"
        options={chartTypes.map((key) => ({
          label: key,
          value: key
        }))}
        onChange={({ value }) => {
          setCurrentChartType(value as SetStateAction<'' | 'SCATTER' | 'BAR' | 'PIE'>);
          setChartProps({ data: currentDataset });
        }}
        value={currentChartType}
      />
      <ChartPropsSelections
        currentChartType={currentChartType}
        fieldOptions={fieldOptions}
        updateProps={(item) => setChartProps({ ...chartProps, ...item })}
        optionalProps={optionalProps}
      />
      <Box sx={{ mb: 2, mt: 2 }}>
        <ChartPreviewWithAudit
          requiredProps={CHART_PROPS_MAP[currentChartType || 'SCATTER'].requiredProps}
          chartProps={chartProps}
          chartType={currentChartType}
          optionalProps={optionalProps}
          chartComponent={
            currentChartType && <Chart data={[]} xField={''} yField={''} sizeField={''} {...chartProps} />
          }
          resetChart={() => {
            setChartProps({ data: currentDataset });
            setCurrentChartType('');
          }}
          dataset={datasetNames[datasetName]}
        />
      </Box>
    </Box>
  );
};
