import Chatbot from '../features/chatbot/components/chat-bot';
import { Page } from '../components/page';
import { useParams } from 'react-router-dom';
import { useGetApi } from '@/hooks/use-get-api';
import { Typography } from '@mui/material';
import { ChatGPTMessage } from '../features/chatbot/api/prism-agent';

export default function Demo() {
  const { projectId, chatId } = useParams<{ projectId: string; chatId: string }>();
  const [chat] = useGetApi<{ conversation: ChatGPTMessage[] }>(`project/${projectId}/chat/${chatId}`);

  return (
    <Page>
      {chat ? (
        <Chatbot initialMessages={chat.conversation} projectId={projectId as string} chatId={chatId as string} />
      ) : (
        <Typography variant="h3" sx={{ display: 'flex', justifyContent: 'center', m: 10 }}>
          Loading...
        </Typography>
      )}
    </Page>
  );
}
