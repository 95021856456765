import { usePostApi } from '@/hooks/use-post-api';
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import { Box, FormControl, IconButton, InputAdornment, OutlinedInput, Tooltip, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useParams } from 'react-router-dom';
import { FileResults, FileSimilaritySearchResult } from './results';
import { StickyChat } from './sticky-chat';
import './style.css';

export const SearchAndChat = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const [results, setResults] = useState<FileSimilaritySearchResult[] | undefined>(undefined);
  const [resultsLoading, setResultsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isChatting, setIsChatting] = useState(false);

  const { postData } = usePostApi<FileSimilaritySearchResult[]>('file/document-similarity-search');

  const runSearch = async (query: string) => {
    try {
      setResultsLoading(true);
      const searchResults = await postData({
        query,
        projectId
      });
      setResults(searchResults);
    } catch (err) {
      console.log('error: ', err);
    } finally {
      setResultsLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h3">Knowledge Finder</Typography>

      <Box display="flex">
        <Box
          className={!isChatting ? 'active' : 'inactive'}
          sx={{ mr: isChatting ? 2 : 0, width: isChatting ? '67%' : '100%', transition: 'width 1200ms' }}
        >
          <FormControl sx={{ mb: 2, mt: 2 }} fullWidth>
            <OutlinedInput
              sx={{ borderRadius: 1 }}
              id="search-box"
              value={searchQuery}
              placeholder="Type in your question here. Click the magnifying glass when you are ready to search"
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  runSearch(searchQuery);
                }
              }}
              startAdornment={
                <InputAdornment position="start">
                  <Tooltip title={searchQuery !== '' && !resultsLoading ? 'Click to search' : ''}>
                    <IconButton
                      aria-label="search"
                      color="info"
                      // don't allow empty search
                      disabled={searchQuery === '' || resultsLoading}
                      onClick={() => runSearch(searchQuery)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              endAdornment={
                searchQuery !== '' ? (
                  <InputAdornment position="end">
                    <IconButton aria-label="clear-search" onClick={() => setSearchQuery('')}>
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <></>
                )
              }
            />
          </FormControl>

          <Box sx={{ mb: 2, mt: 2 }}>
            {resultsLoading ? (
              <Typography variant="h3" sx={{ fontSize: '1.7rem' }}>
                Searching...
              </Typography>
            ) : (
              results && (
                <FileResults isChatting={isChatting} results={results} onChatClicked={() => setIsChatting(true)} />
              )
            )}
          </Box>
        </Box>

        <StickyChat
          isChatting={isChatting}
          results={results || []}
          projectId={projectId!}
          onChatClosed={() => setIsChatting(false)}
          query={searchQuery}
        />
      </Box>
    </Box>
  );
};
