import axios from 'axios';
import { useEffect, useState } from 'react';
import { useAuthToken } from './use-auth-token';

export const useGetApi = <T,>(endpoint: string, initialData: T | null = null): [T | null, Error | null] => {
  const [data, setData] = useState<T | null>(initialData);
  const [error, setError] = useState<Error | null>(null);

  const fetchToken = useAuthToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await fetchToken();
        const response = await axios.get(
          `${
            import.meta.env.VITE_PRISM_BACKEND_ROOT_ENDPOINT || 'https://backend-fftuh3xouq-uc.a.run.app'
          }/${endpoint}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        setData(response.data);
      } catch (error) {
        console.error(`Error loading data from ${endpoint}:`, error);
        setError(error instanceof Error ? error : new Error(String(error)));
      }
    };

    fetchData();
  }, [endpoint, fetchToken]);

  return [data, error];
};
