import { useNavBarMenu } from '@/features/navbar';
import Close from '@mui/icons-material/Close';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { StyledTextField } from '../vault-layout';
import { VaultMap } from './vault-map';
import { Theme } from '@mui/system';
import theme from '@/styles/theme';

// sidebar for vaults
export const VaultSideNav = () => {
  const [input, setInput] = useState('');
  const [filterString, setFilterString] = useState('');

  const { setDrawerOpen } = useNavBarMenu();

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        paddingTop: isSmallScreen ? theme.spacing(6) : 0,
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }}
    >
      {isSmallScreen && (
        <Box
          sx={{
            position: 'fixed',
            top: theme.spacing(12),
            left: theme.spacing(5),
            color: theme.palette.blue.dark,
            zIndex: 10
          }}
          onClick={() => setDrawerOpen(false)}
        >
          <Close style={{ cursor: 'pointer', fontSize: '24px' }} />
        </Box>
      )}
      <Box sx={{ pt: [7, 2], height: '100%', position: 'relative' }}>
        <Box sx={{ mt: isSmallScreen ? 2 : 0, mr: 3, ml: 3 }}>
          <StyledTextField
            fullWidth
            label="Filter"
            variant="standard"
            size="small"
            sx={{
              input: { fontSize: 12 },
              label: { fontSize: 12, paddingLeft: '9%' },
              paddingLeft: '9%',
              paddingRight: '9%'
            }}
            onChange={(e) => setInput(e.target.value)}
            value={input}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                setFilterString(input);
              }
            }}
          />
          <Box
            sx={{
              mt: 2,
              maxHeight: '60vh',
              overflowY: 'scroll',
              pb: '20vh',
              // hide the native scrollbar until we have a better style for it.
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none'
              }
            }}
          >
            <VaultMap filter={filterString} />
          </Box>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: isSmallScreen ? theme.spacing(-3) : theme.spacing(3),
            height: isSmallScreen ? '140px' : '80px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            pr: 2,
            pl: 2,
            pb: isSmallScreen ? theme.spacing(5) : 0,
            boxShadow: '0 -2px 2px -2px #ccc',
            backgroundColor: theme.palette.background.default
          }}
        >
          <a href="https://www.prism.bio">
            <Button
              sx={{
                color: theme.palette.green.dark,
                backgroundColor: theme.palette.green.main,
                borderRadius: theme.borderRadius,
                minWidth: '165px',
                fontWeight: 450
              }}
            >
              Try Prism
            </Button>
          </a>
        </Box>
      </Box>
    </Box>
  );
};
