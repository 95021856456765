import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Typography
} from '@mui/material';
import { uniqBy } from 'lodash';

export type SelectOption = { label: string; value: string | number };

type SelectProps = {
  options: SelectOption[];
  showNoneOption?: boolean;
  placeholderText?: string;
  disabledText?: string;
  label?: string;
  onChange?: (value: SelectOption) => void;
} & Omit<MuiSelectProps, 'onChange'>;

// Wrapper for MUI select
export const Select = ({
  options,
  showNoneOption = false,
  placeholderText,
  disabledText,
  size,
  disabled,
  onChange,
  label,
  ...props
}: SelectProps): JSX.Element => {
  if (uniqBy(options, 'value').length !== options.length) {
    console.warn('Select: Non-unique values detected in options');
  }
  return (
    <>
      {label && (
        <Typography variant="caption" sx={{ mr: 3, minWidth: 50 }}>
          {label}
        </Typography>
      )}
      <FormControl fullWidth>
        {placeholderText && (
          <InputLabel id="select-label" size={size === 'small' ? 'small' : 'normal'}>
            {placeholderText}
          </InputLabel>
        )}
        <MuiSelect
          labelId="select-label"
          label={placeholderText}
          onChange={(event) => {
            const value = event.target.value;
            const selectedOption =
              value === '' ? { value: '', label: 'None' } : options.find((option) => option.value === value);

            if (onChange && selectedOption) {
              onChange(selectedOption);
            }
          }}
          size={size}
          disabled={disabled}
          {...props}
        >
          {showNoneOption && (
            <MenuItem key="None" value="">
              None
            </MenuItem>
          )}
          {options.map(({ label, value }, index) => (
            <MenuItem key={`${value}-${index}`} value={value}>
              {label}
            </MenuItem>
          ))}
        </MuiSelect>
        {disabledText && disabled && (
          <FormHelperText>
            <Typography variant="body2" color="error">
              {disabledText}
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
    </>
  );
};
