import { Box, Card, Typography } from '@mui/material';
import { Page } from '../components/page';

export default function RequestAccessPage() {
  return (
    <Page>
      <Card
        variant="outlined"
        sx={{
          maxWidth: 400,
          minHeight: 300,
          m: 'auto',
          backgroundColor: 'info'
        }}
      >
        <Box sx={{ m: 5, textAlign: 'center' }}>
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Request Access
          </Typography>
          <Typography variant="body1" sx={{ mt: 5 }}>
            Email <b>support@prism.bio</b> to request access.
          </Typography>
        </Box>
      </Card>
    </Page>
  );
}
