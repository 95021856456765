import { ExhibitBuilder } from '@/features/exhibit-builder';
import { Page } from '../components/page';

export default function ExhibitBuilderPage() {
  return (
    <Page>
      <ExhibitBuilder
        title="New Scientific Report"
        exhibitTitle="Exhibit 1"
        metaDataFields={['Title', 'Caption', 'Comment']}
      />
    </Page>
  );
}
