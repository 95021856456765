import { usePostFileApi } from '@/hooks/use-post-file-api';
import theme from '@/styles/theme';
import { Box } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FileInputButton } from './data-upload-files-input-button';
import { Header } from './data-upload-header';
import { ProjectFilesHeader } from './data-upload-project-files-header';
import { FilesStatusTable } from './data-upload-table';

// #region interfaces
interface UploadFileResponse {
  id: string;
  fileName: string;
  projectId: string;
}

// #endregion interfaces

export const DataUpload = () => {
  const { projectId } = useParams<{ projectId: string }>();

  const [isUploading, setIsUploading] = useState(false);

  const { usePostFileApi: uploadPdf } = usePostFileApi<UploadFileResponse>('file/pdf/upload');
  const { usePostFileApi: uploadPpt } = usePostFileApi<UploadFileResponse>('file/ppt/upload');

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      setIsUploading(true);
      if (event.target.files?.length) {
        for (const file of event.target.files) {
          const payload = {
            fileName: file.name,
            projectId
          };
          if (file.type === 'application/pdf') {
            await uploadPdf(payload, file);
          } else if (
            file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
            file.type === 'application/vnd.ms-powerpoint'
          ) {
            await uploadPpt(payload, file);
          } else {
            throw new Error('Unsupported file type');
          }
        }
      }
    } catch (error) {
      console.error('failed initial file handoff: ', error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={5}
      width="100%" // Ensure parent has full width
    >
      <Header />
      <Box display="flex" flexDirection="column" alignItems="center" zIndex={2}>
        <ProjectFilesHeader />
        <Box
          sx={{
            border: `1px solid ${theme.palette.grey[400]}`,
            borderRadius: 1,
            p: 3,
            mt: -2,
            width: '30%',
            minWidth: 875,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <FileInputButton isUploading={isUploading} handleFileUpload={handleFileUpload} />
          <FilesStatusTable />
        </Box>
      </Box>
    </Box>
  );
};
