import theme from '@/styles/theme';
import { useAuth0 } from '@auth0/auth0-react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, ButtonBase, MenuItem, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const NavBarProfile = () => {
  const { user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <ButtonBase aria-describedby={id} onClick={handleClick}>
        <Typography sx={{ color: theme.palette.blue.dark }} variant="h6">
          {user?.email}
        </Typography>
        <ArrowDropDownIcon color="primary" />
      </ButtonBase>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem onClick={handleClose} sx={{ backgroundColor: theme.palette.background.default }}>
          <Typography
            variant="h6"
            component={Link}
            sx={{ textDecoration: 'none', color: 'text.primary' }}
            to="/user-settings"
          >
            Settings
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: theme.palette.background.default }}
          onClick={() => {
            logout({ logoutParams: { returnTo: window.location.origin } });
            handleClose();
          }}
        >
          <Typography variant="h6">Logout</Typography>
        </MenuItem>
      </Popover>
    </Box>
  );
};
