import axios, { AxiosResponse } from 'axios';
import { Reducer, useCallback, useReducer } from 'react';
import slugify from 'slugify';
import { FileWithPreview } from '@/types/files';
import { parse } from 'papaparse';
import { useAuthToken } from './use-auth-token';

interface UploadState {
  uploading: boolean;
  uploaded: Array<unknown>;
  errors: Array<unknown>;
}

interface UploadAction {
  type: 'UPLOAD_START' | 'UPLOAD_SUCCESS' | 'UPLOAD_FAILURE' | 'UPLOAD_COMPLETE';
  payload?: unknown;
  error?: unknown;
}

const initialState: UploadState = {
  uploading: false,
  uploaded: [],
  errors: []
};

const reducer: Reducer<UploadState, UploadAction> = (state, action) => {
  switch (action.type) {
    case 'UPLOAD_START':
      return { ...state, uploading: true };
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        uploaded: [...state.uploaded, action.payload!],
        uploading: false
      };
    case 'UPLOAD_FAILURE':
      return {
        ...state,
        errors: [...state.errors, action.error!],
        uploading: false
      };
    case 'UPLOAD_COMPLETE':
      return { ...state, uploading: false };
    default:
      return state;
  }
};

const useUploadCsv = (projectId: string, chatId?: string) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchToken = useAuthToken();

  const upload = useCallback(
    async (files: FileWithPreview[]) => {
      dispatch({ type: 'UPLOAD_START' });

      const token = await fetchToken();
      const uploads: Array<Promise<AxiosResponse | void>> = Array.from(files).map(async (file: File) => {
        const datasetName = slugify(file.name, { lower: true, strict: true });

        let sampleRows: string[][] = [];
        await new Promise((resolve) => {
          parse(file, {
            preview: 3,
            complete: (results: { data: string[][] }) => {
              sampleRows = results.data;
              resolve(null);
            }
          });
        });

        const fileAnalystFulfillResponsibilityDto = {
          dataset_name: datasetName,
          project_id: projectId,
          sampleRows,
          chat_id: chatId
        };

        const formData = new FormData();
        formData.append('file', file);
        formData.append('body', JSON.stringify(fileAnalystFulfillResponsibilityDto));

        try {
          const response = axios.post<AxiosResponse>(
            `${
              import.meta.env.VITE_PRISM_BACKEND_ROOT_ENDPOINT || 'https://backend-fftuh3xouq-uc.a.run.app'
            }/ai/agent/file-analyst/fulfill-responsibility`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
              }
            }
          );
          dispatch({ type: 'UPLOAD_SUCCESS', payload: response });
          return response;
        } catch (error) {
          dispatch({ type: 'UPLOAD_FAILURE', error });
        }
      });

      await Promise.all(uploads);
      dispatch({ type: 'UPLOAD_COMPLETE' });
    },
    [chatId, fetchToken, projectId]
  );

  return { ...state, upload };
};

export default useUploadCsv;
