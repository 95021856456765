import { IconButton, Dialog, Box, Typography, Tooltip } from '@mui/material';
import { useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import { isEmpty } from 'lodash';

type OptionalParamsDialogProps = {
  optionalProps: Record<string, unknown>;
};
/**
 * Dialog showing the optional chart props for a selected chart
 */
export const OptionalParamsDialog = ({ optionalProps }: OptionalParamsDialogProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  return isEmpty(optionalProps) ? (
    <></>
  ) : (
    <>
      <Tooltip title="View Optional Parameters">
        <IconButton onClick={() => setOpen(true)}>
          <DescriptionIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={() => setOpen(false)} sx={{ minHeight: 200, minWidth: 300 }}>
        <Box sx={{ m: 5 }}>
          <Typography variant="h5">Optional Parameters</Typography>
          <ul>
            {!isEmpty(optionalProps) &&
              Object.keys(optionalProps).map((key, value) => (
                <li key={`${key}-${value}`}>
                  <Typography variant="body1">{key}</Typography>
                </li>
              ))}
          </ul>
        </Box>
      </Dialog>
    </>
  );
};
