import { useCallback, useState } from 'react';
import { AgentToInteractWith, ChatGPTMessage, PrismAgentStream } from '../features/chatbot/api/prism-agent';
import { FileWithPreview } from '@/types/files';
import { FileSimilaritySearchResult } from '../features/knowledge-finder/components/search-data/components';
import { useAuthToken } from './use-auth-token';
import useUploadCsv from './use-upload-csv';

export type SendMessageParams = {
  agent: AgentToInteractWith;
  message: string;
  setFiles?: (files: FileWithPreview[]) => void;
  files?: FileWithPreview[];
  query?: string;
  setResults?: (results: FileSimilaritySearchResult[]) => void;
  results?: string;
  model?: string;
  vaultItem?: string;
  extras?: object; // any distinct properties that should be passed to the backend
};

export function useAgentChat({
  initialMessages = [],
  projectId = '',
  chatId,
  preserveInitialAssistantMessage = false
}: {
  initialMessages: ChatGPTMessage[];
  projectId?: string;
  chatId?: string;
  preserveInitialAssistantMessage?: boolean;
}) {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState(initialMessages);
  const [error, setError] = useState<Error>();

  const { upload } = useUploadCsv(projectId, chatId);

  const fetchToken = useAuthToken();

  const sendMessage = useCallback(
    async ({ agent, message, setFiles, files, results, model, query, extras }: SendMessageParams) => {
      setLoading(true);
      const oldMessages = messages.length > 1 ? messages : preserveInitialAssistantMessage ? messages : [];
      const newMessages = [...oldMessages, { role: 'user', content: message } as ChatGPTMessage];
      setMessages(newMessages);

      try {
        let token;
        try {
          token = await fetchToken(true);
        } catch (err) {
          console.warn('attempting auth-less chat');
        }

        if (files && files.length > 0) {
          const filesCopy = files?.slice();
          if (setFiles) setFiles([]);
          await upload(filesCopy);
        }
        const stream = await PrismAgentStream({
          payload: {
            model: model ?? import.meta.env.VITE_OPENAI_DEFAULT_MODEL ?? 'gpt-4-0613',
            messages: newMessages,
            temperature: 0.7,
            max_tokens: 1000,
            top_p: 1.0,
            frequency_penalty: 0.0,
            presence_penalty: 0.6,
            stream: true
          },
          authToken: token,
          projectId,
          chatId,
          agent,
          searchResults: results,
          query,
          extras
        });

        const reader = stream.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let buffer = '';

        while (!done) {
          const { value, done: doneReading } = await reader.read();
          done = doneReading;
          const chunkValue = decoder.decode(value);
          buffer += chunkValue;

          let parsedValue;
          try {
            parsedValue = JSON.parse(buffer);
          } catch (error) {
            // Ignore SyntaxError as it likely means the function call or message is split across chunks.
            if (!(error instanceof SyntaxError)) {
              throw error;
            }
          }

          if (parsedValue) {
            // A complete JSON object (function call or message) was received, handle it.
            newMessages.push(parsedValue);
            setMessages([...newMessages]);
            buffer = ''; // Clear buffer after processing function call or message.
          } else {
            // JSON parsing failed, so buffer must be a string message.
            // Update the last message
            if (newMessages.length > 0 && newMessages[newMessages.length - 1].role === 'assistant') {
              newMessages[newMessages.length - 1].content = buffer;
              setMessages([...newMessages]); // Update UI immediately.
            } else {
              // Or add a new message if the last one is not from assistant
              newMessages.push({ role: 'assistant', content: buffer });
              setMessages([...newMessages]); // Update UI immediately.
            }
          }
        }

        setLoading(false);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error(err);
        setError(err);
        setLoading(false);
      }
    },
    [messages, fetchToken, projectId, chatId, upload, preserveInitialAssistantMessage]
  );

  return {
    loading,
    messages,
    error,
    sendMessage
  };
}
