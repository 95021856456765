import { LinkWithQuery } from '@/components';
import theme from '@/styles/theme';
import { Box, CircularProgress, Divider, Link, styled } from '@mui/material';
import MuiMarkdown, { getOverrides } from 'mui-markdown';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { RenderedChart } from './charts/vault-chart-map';
import { SearchView } from './search';
import { SessionStorageKey, setItemInSessionStorage } from '@/stores/session-storage-utils';
import { VaultLayoutScrollContext } from '../stores/vault-context';
import '@react-sigma/core/lib/react-sigma.min.css';

// todo: move these to a types file
export interface MarkdownSectionContent {
  type: 'md';
  content: string;
}

enum AvailableChartTypes {
  Pie = 'pie',
  Bar = 'bar',
  Scatter = 'scatter',
  Network = 'network'
}

export interface ChartSectionContent {
  type: 'chart';
  configuration?: {
    type: keyof typeof AvailableChartTypes;
    props: { [key: string]: string };
  };
  data: (number | string)[][];
}

interface DividerSectionContent {
  type: 'divider';
}

interface DeviceListSectionContent {
  type: 'links';
  links: { name: string; path: string }[];
}

export type SectionContentType =
  | MarkdownSectionContent
  | ChartSectionContent
  | DividerSectionContent
  | DeviceListSectionContent;

const MarkdownWrapper = styled(Box)(() => ({
  '& body': {
    color: 'black'
  },
  '& h2': {
    fontSize: '2.8rem',
    fontFamily: 'Canela Text',
    fontWeight: 100,
    marginBottom: '2rem',
    marginTop: '4rem'
  },
  '& h3': {
    fontSize: '1.8rem',
    fontFamily: 'Canela Text',
    fontWeight: 100,
    marginBottom: '2rem'
  },
  '& p': {
    fontSize: '0.9rem',
    lineHeight: '1.5rem',
    marginBottom: '1rem'
  },
  '& span': {
    fontSize: '0.9rem',
    lineHeight: '1.5rem',
    marginBottom: '1rem'
  },
  '& a': {
    color: '#70a9c9'
  }
}));

const VaultItemContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        pb: '20vh',
        justifyContent: 'center',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          paddingRight: { xs: 5, sm: 5, md: 0 },
          paddingLeft: { xs: 5, sm: 5, md: 0 },
          minWidth: 400,
          maxWidth: 600,
          ml: 10,
          mr: 10
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export const VaultItem = (): JSX.Element => {
  const { page, tier, vaultName } = useParams();
  const { search } = useLocation();

  const [data, setData] = useState<SectionContentType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const scrollContainerRef = useContext(VaultLayoutScrollContext);

  useEffect(() => {
    const url = `https://vault.prism.bio/${vaultName}/${tier}/${encodeURIComponent(page!)}.json`;
    setLoading(true);

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('failed to fetch article');
        }
        return response.json();
      })
      .then((jsonData) => {
        setData(jsonData);
        setItemInSessionStorage(SessionStorageKey.VaultRecentItem, JSON.stringify(jsonData));
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        if (scrollContainerRef && scrollContainerRef.current) {
          scrollContainerRef.current.scrollTop = 0;
        }
        setLoading(false);
      });
  }, [scrollContainerRef, page, tier, vaultName]);

  if (loading) {
    return (
      <VaultItemContainer>
        <Box sx={{ height: '100vh' }}>
          <CircularProgress
            size={48}
            sx={{
              color: theme.palette.blue.main,
              position: 'absolute',
              top: '30%', // Move the spinner's top pivot point up
              left: 'calc(50% - 24px)', // horizontally center
              transform: 'translate(-50%, -50%)', // Center based on the new pivot
              zIndex: 1
            }}
          />
        </Box>
      </VaultItemContainer>
    );
  }

  if (error) {
    return <VaultItemContainer>Error: {error}</VaultItemContainer>;
  }

  return (
    <VaultItemContainer key={`${page}${tier}${vaultName}`}>
      {data &&
        data.map((section: SectionContentType, index: number) => {
          if (section.type === 'md') {
            return (
              <Box key={index} sx={{ mt: 0 }}>
                <MarkdownWrapper>
                  <MuiMarkdown
                    overrides={{
                      ...getOverrides(),
                      Link: {
                        // links with query params to preserve search
                        component: LinkWithQuery,
                        props: {
                          style: { color: '#70a9c9' }
                        }
                      },
                      a: {
                        props: {
                          target: '_blank'
                        }
                      }
                    }}
                  >
                    {section.content}
                  </MuiMarkdown>
                </MarkdownWrapper>
              </Box>
            );
          } else if (section.type === 'chart') {
            return (
              <Box key={index} display="flex" flexDirection="column" sx={{ mt: 6, mb: 6 }}>
                <RenderedChart chartSection={section} />
              </Box>
            );
          } else if (section.type === 'divider') {
            return <Divider key={index} sx={{ mt: 8, mb: 8 }} />;
          } else if (section.type === 'links') {
            return (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                {section.links.map(({ name, path }: { name: string; path: string }) => {
                  return (
                    <Link key={name} color={'#70a9c9'} component={RouterLink} to={`${path}${search}`} sx={{ mr: 1 }}>
                      {name}
                    </Link>
                  );
                })}
              </Box>
            );
          }
        })}
    </VaultItemContainer>
  );
};

export const VaultView = () => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('search');
  const showSearch = searchParams.get('isSearchActive') === 'true';

  // conditionally show search results view
  return showSearch ? <SearchView query={searchQuery || ''} /> : <VaultItem />;
};
