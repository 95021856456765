import theme from '@/styles/theme';
import { List, ListItem, Card, Typography } from '@mui/material';
import { ChatGPTMessage } from '../api/prism-agent';
import { MuiMarkdown } from 'mui-markdown';
import { Variant } from '@mui/material/styles/createTypography';

type MessageListProps = {
  messages: ChatGPTMessage[];
  messageFontVariant?: Variant;
};

export const MessageList = ({ messages, messageFontVariant }: MessageListProps) => (
  <List>
    {messages.map((msg, index) => (
      <ListItem
        key={index}
        sx={{
          justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start'
        }}
      >
        <Card
          variant="outlined"
          sx={{
            p: theme.spacing(2),
            maxWidth: '90%',
            backgroundColor: msg.role === 'user' ? theme.palette.info.light : theme.palette.secondary.light
          }}
        >
          <Typography variant={messageFontVariant ?? 'body1'}>
            <MuiMarkdown>{msg.content}</MuiMarkdown>
          </Typography>
        </Card>
      </ListItem>
    ))}
  </List>
);
