import { Box, Typography } from '@mui/material';

export const ProjectFilesHeader = () => (
  <Box
    sx={{
      backgroundColor: 'white',
      width: 'fit-content',
      pl: 5,
      pr: 5,
      zIndex: 2
    }}
  >
    <Typography variant="h3" sx={{ fontSize: '1.8rem' }}>
      PROJECT FILES
    </Typography>
  </Box>
);
