import { Link } from '@mui/material';
import { useLocation, Link as RouterLink, LinkProps } from 'react-router-dom';

export const LinkWithQuery = ({ children, to, ...props }: LinkProps) => {
  const { search } = useLocation();
  return (
    <Link component={RouterLink} to={to + search} {...props}>
      {children}
    </Link>
  );
};
