import { IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

type SendButtonProps = {
  handleSendMessage: (message: string) => void;
  input: string;
};

type UploadButtonProps = {
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
};

export const SendButton = ({ handleSendMessage, input }: SendButtonProps) => (
  <IconButton color="primary" aria-label="send message" onClick={() => handleSendMessage(input)}>
    <SendIcon />
  </IconButton>
);

export const UploadButton = ({ getInputProps, getRootProps }: UploadButtonProps) => (
  <>
    <input {...getInputProps()} style={{ display: 'none' }} />
    <IconButton color="primary" aria-label="upload file" {...getRootProps()} component="span">
      <AttachFileIcon />
    </IconButton>
  </>
);
