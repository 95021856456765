export enum SessionStorageKey {
  VaultRecentItem = 'vault-recent-item',
  VaultSearchResults = 'vault-search-results'
}

/**
 * retrieves an item from session storage
 */
export function getItemFromSessionStorage(key: SessionStorageKey): string | null {
  const item = sessionStorage.getItem(key);
  return item;
}

/**
 * sets an item into session storage
 */
export function setItemInSessionStorage(key: SessionStorageKey, value: string): void {
  sessionStorage.setItem(key, value);
}
