import { createParser, ParsedEvent, ReconnectInterval } from 'eventsource-parser';

export type ChatGPTAgent = 'user' | 'system' | 'assistant' | 'function';

export interface ChatGPTMessage {
  role: ChatGPTAgent;
  content: string;
}

export interface OpenAIPayload {
  model: string;
  messages: ChatGPTMessage[];
  temperature: number;
  top_p: number;
  frequency_penalty?: number;
  presence_penalty?: number;
  max_tokens: number;
  stream: boolean;
  stop?: string[];
  user?: string;
  n?: number;
  functions?: object[];
}

export enum AgentToInteractWith {
  FileAnalyst = '/ai/agent/file-analyst',
  Interviewer = '/ai/agent/interviewer/stream-chat',
  DocChat = '/ai/agent/doc-chat/stream-chat',
  VaultPublicChatWithOneArticle = '/ai/agent/public-vault-chat/stream-chat-with-one-article',
  VaultPublicChatWithSearchResults = '/ai/agent/public-vault-chat/stream-chat-with-search-results'
}

async function* streamAsyncIterator(stream: ReadableStream<Uint8Array>) {
  const reader = stream.getReader();

  try {
    while (true) {
      const { value, done } = await reader.read();

      if (done) {
        break;
      }

      yield value;
    }
  } finally {
    reader.releaseLock();
  }
}

export async function PrismAgentStream({
  payload,
  authToken,
  projectId,
  chatId,
  agent,
  query,
  searchResults,
  extras
}: {
  payload: OpenAIPayload;
  authToken?: string;
  projectId?: string;
  chatId?: string;
  agent: AgentToInteractWith;
  query?: string;
  searchResults?: string;
  extras?: object;
}) {
  const encoder = new TextEncoder();
  const decoder = new TextDecoder();

  let counter = 0;

  const requestHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`
  };

  let res: Response | undefined;
  try {
    res = await fetch(
      `${import.meta.env.VITE_PRISM_BACKEND_ROOT_ENDPOINT || 'https://backend-fftuh3xouq-uc.a.run.app'}${agent}`,
      {
        headers: requestHeaders,
        method: 'POST',
        body: JSON.stringify({
          ...payload,
          project_id: projectId,
          chat_id: chatId,
          query,
          searchResults,
          ...extras
        })
      }
    );
  } catch (error) {
    console.error('Network error:', error);
    // Handle network error as needed
    throw error;
  }

  let stream;
  try {
    stream = new ReadableStream({
      async start(controller) {
        async function onParse(event: ParsedEvent | ReconnectInterval) {
          if (event.type === 'event') {
            const data = event.data;

            if (data === '[DONE]') {
              controller.close();
              return;
            }

            if (counter < 2 && (data.match(/\n/) || []).length) {
              // this is a prefix character (i.e., "\n\n"), do nothing
              return;
            }
            const queue = encoder.encode(data);
            controller.enqueue(queue);
            counter++;
          }
        }

        // stream response (SSE) from OpenAI may be fragmented into multiple chunks
        // this ensures we properly read chunks and invoke an event for each SSE event stream
        const parser = createParser(onParse);

        // custom async iterator using the function we defined above.
        if (res?.body) {
          for await (const chunk of streamAsyncIterator(res.body)) {
            parser.feed(decoder.decode(chunk));
          }
        }
      }
    });
  } catch (error) {
    console.error('Stream error:', error);
    // Handle stream error as needed
    throw error;
  }

  return stream;
}
