import { Box, TextField, Typography } from '@mui/material';

/**
 * Temp component for exhibit builder metadata form
 */
// TODO: add form validation
export const ExhibitMetadata = ({ fields }: { fields: string[] }) => {
  return (
    <Box component="form" sx={{ mt: 3 }}>
      {fields.map((field, index) => (
        <>
          <Typography variant="caption" sx={{ mr: 3, minWidth: 50 }} key={`${field}-${index}-label`}>
            {field}
          </Typography>
          <TextField required fullWidth multiline label={field} key={`${field}-${index}-text`} sx={{ mb: 4 }} />
        </>
      ))}
    </Box>
  );
};
