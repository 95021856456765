import { LoginButton } from '@/features/auth';
import { useAuth0 } from '@auth0/auth0-react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { Theme, lighten } from '@mui/system';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavBar, useNavBarMenu } from '../stores/navbar-context';
import { NavBarProfile } from './nav-bar-profile';

const DialogTransition = forwardRef<
  unknown,
  TransitionProps & {
    children: React.ReactElement;
  }
>((props, ref) => {
  return <Slide direction="right" ref={ref} {...props} />;
});

export const NavBar = () => {
  const theme = useTheme();
  const trigger = useScrollTrigger();
  const { isAuthenticated, isLoading } = useAuth0();

  const { title } = useNavBar();
  const { content, drawerOpen, setDrawerOpen } = useNavBarMenu();

  const isMdScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Slide appear={false} in={!trigger} direction="down">
      <AppBar sx={{ backgroundColor: theme.palette.blue.main }} elevation={0}>
        <Toolbar id="nav-bar" sx={{ justifyContent: 'space-between' }}>
          <IconButton
            sx={{ display: { xs: 'block', md: 'none' } }}
            color="inherit"
            edge="start"
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon sx={{ color: theme.palette.blue.dark }} />
          </IconButton>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Link
              to="/"
              style={{
                color: theme.palette.blue.dark,
                textDecoration: 'none',
                fontFamily: 'Aeonik',
                fontSize: 24,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal'
              }}
            >
              ✦ Prism
            </Link>
          </Box>
          {title ? (
            <Typography sx={{ fontFamily: 'Canela Text', mr: 1, fontSize: isMdScreen ? 14 : 18 }} variant="body1">
              {title.split(':').map((part, index) => (
                <span
                  key={index}
                  style={{
                    color: index === 0 ? lighten(theme.palette.blue.dark, 0.3) : theme.palette.blue.dark
                  }}
                >
                  {part}
                  {index === 0 && ':'}
                </span>
              ))}
            </Typography>
          ) : isLoading ? (
            <Typography color="primary" variant="h6">
              Loading...
            </Typography>
          ) : isAuthenticated ? (
            <NavBarProfile />
          ) : (
            <LoginButton />
          )}
        </Toolbar>
        {isMdScreen && (
          <Dialog
            TransitionComponent={DialogTransition}
            hideBackdrop={true}
            sx={{
              '& .MuiDialog-paper': {
                borderRadius: 0,
                margin: 0,
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: 0, // Locked to the left edge
                minWidth: { xs: '75%', sm: '300px', md: '300px' },
                maxWidth: {
                  xs: '100%', // take full width on extra small screens
                  sm: '50%', // take 50% of the width on small screens
                  md: '50%' // take 50% on medium screens and above
                },
                height: '100vh',
                maxHeight: '100vh',
                scrollbarWidth: 'none',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }
            }}
            open={drawerOpen}
            onClose={() => {
              setDrawerOpen(false);
            }}
          >
            {content}
          </Dialog>
        )}
      </AppBar>
    </Slide>
  );
};
