import theme from '@/styles/theme';
import { Card, Grid, Typography, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import CsvIcon from '@mui/icons-material/GridOn';
import PptIcon from '@mui/icons-material/Slideshow';
import ImageIcon from '@mui/icons-material/Image';
import { ReactNode } from 'react';

const getFileExtension = (filename: string) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? '' : ext[1];
};

type FileCardProps = {
  file: File & { preview: string };
  handleFileRemove: (file: File) => void;
};

const truncateFileName = (filename: string, maxLength: number) => {
  const extension = getFileExtension(filename);
  const baseName = filename.substring(0, filename.length - extension.length - 1);
  const truncatedBaseName = baseName.length > maxLength ? baseName.substring(0, maxLength) + '..' : baseName;
  return truncatedBaseName + '.' + extension;
};

const fileExtensionProps: {
  [key: string]: { color: string; icon: ReactNode };
} = {
  pdf: { color: theme.palette.error.light, icon: <PdfIcon /> },
  csv: { color: theme.palette.success.main, icon: <CsvIcon /> },
  ppt: { color: theme.palette.secondary.main, icon: <PptIcon /> },
  jpeg: { color: theme.palette.info.main, icon: <ImageIcon /> },
  jpg: { color: theme.palette.info.main, icon: <ImageIcon /> },
  png: { color: theme.palette.info.main, icon: <ImageIcon /> },
  gif: { color: theme.palette.info.main, icon: <ImageIcon /> }
};

export const FileCard = ({ file, handleFileRemove }: FileCardProps) => {
  const theme = useTheme();
  const extProps = fileExtensionProps[getFileExtension(file.name)] || {};
  return (
    <Card
      variant="outlined"
      sx={{
        mr: theme.spacing(2),
        mt: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: '200px',
        overflow: 'visible'
      }}
    >
      <IconButton
        style={{
          position: 'absolute',
          top: -15,
          right: -15,
          backgroundColor: '#fff',
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
        }}
        onClick={() => handleFileRemove(file)}
      >
        <CloseIcon fontSize="small" />
      </IconButton>

      <Grid container>
        <Grid
          item
          xs={4}
          sx={{
            backgroundColor: extProps.color,
            color: theme.palette.common.white,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            backgroundImage: `url(${file.preview})`,
            backgroundSize: 'cover'
          }}
        >
          {!file.preview ||
            (!file.type.includes('image/') && (
              <Grid
                item
                xs={4}
                sx={{
                  backgroundColor: extProps.color,
                  color: theme.palette.common.white,
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  padding: theme.spacing(1)
                }}
              >
                {extProps.icon}
              </Grid>
            ))}
        </Grid>

        <Grid item xs={8} sx={{ padding: theme.spacing(2) }}>
          <Typography variant="body2">{truncateFileName(file.name, 10)}</Typography>
          <Typography variant="body2">{Math.round(file.size / 1000000)} MB</Typography>
        </Grid>
      </Grid>
    </Card>
  );
};
