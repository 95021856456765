import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function LoginPage() {
  const { isLoading, loginWithRedirect } = useAuth0();
  // get redirect path to send user along after login
  const { state } = useLocation();

  useEffect(() => {
    const logIn = () => loginWithRedirect({ appState: { returnTo: state || '/demo' } });
    logIn().catch((e) => console.error('Login error: ', e));
  }, [isLoading, loginWithRedirect, state]);

  return (
    // todo: replace with cooler loader
    <Typography variant="h3" sx={{ display: 'flex', justifyContent: 'center', m: 10 }}>
      Loading...
    </Typography>
  );
}
