import { Card, styled } from '@mui/material';

// styled card with hover effect
export const StyledCard = styled(Card)(({ theme }) => ({
  height: 300,
  ':hover': {
    transition: theme.transitions.create(['boxShadow'], {
      duration: theme.transitions.duration.standard
    }),
    boxShadow: theme.shadows[2],
    border: '1px solid black'
  }
}));
