import { Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { UserProfile } from '../types/user-settings';
import { useGetApi } from '@/hooks/use-get-api';

export const UserSettings = () => {
  const [userProfile] = useGetApi<UserProfile>('auth/me');

  if (!userProfile) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <Typography variant="h6" component="div" sx={{ marginRight: 2 }}>
          Loading
        </Typography>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h4" component="div">
          User Settings
        </Typography>
        <Typography variant="h6">First Name: {userProfile.firstName}</Typography>
        <Typography variant="h6">Last Name: {userProfile.lastName}</Typography>
        <Typography variant="h6">User ID: {userProfile.userId}</Typography>
        <Typography variant="h6">Email: {userProfile.email}</Typography>
        <Typography variant="h6">Email Verified: {userProfile.emailVerified ? 'Yes' : 'No'}</Typography>
      </CardContent>
    </Card>
  );
};
