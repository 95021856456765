import { SelectOption } from '@/components';
import { Box, TextField, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { CHART_COMPONENTS_MAP } from '../../constants';
import { OptionalParamsDialog } from './optional-params-dialog';
import { RequiredPropsSelections } from './required-props-selections';

type PropsSelectionProps = {
  currentChartType: keyof typeof CHART_COMPONENTS_MAP | '';
  fieldOptions: SelectOption[];
  optionalProps?: Record<string, unknown>;
  updateProps: (item: Record<string, unknown>) => void;
};

/**
 * For choosing props
 */
export const ChartPropsSelections = ({
  currentChartType,
  fieldOptions,
  updateProps,
  optionalProps
}: PropsSelectionProps) => {
  return (
    <Box sx={{ ml: 10 }}>
      {currentChartType && (
        <>
          <RequiredPropsSelections
            currentChartType={currentChartType}
            fieldOptions={fieldOptions}
            updateProps={(item) => updateProps(item)}
          />
          {!isEmpty(optionalProps) && (
            <>
              <Typography variant="caption" sx={{ mr: 3, minWidth: 50 }}>
                Params
              </Typography>
              <Box display="flex">
                <TextField
                  fullWidth
                  placeholder={`{"colorScheme":"category20", "xAxisTitle":"A Title", ...}`}
                  size="small"
                  label="Additional Parameters"
                  onChange={(e) => {
                    try {
                      const jsonParams = JSON.parse(e.target.value);
                      updateProps(jsonParams);
                    } catch (error) {
                      console.error('Error parsing json:', error);
                    }
                  }}
                />
                <OptionalParamsDialog optionalProps={optionalProps} />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};
