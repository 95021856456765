import axios from 'axios';
import { useCallback, useState } from 'react';

type ReturnType<T> = { postData: (data: object) => Promise<T>; data: T | null; error: Error | null | unknown };

export const usePostApiWithoutAuth = <T,>(endpoint: string): ReturnType<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null | unknown>(null);

  const postData = useCallback(
    async (body: object) => {
      try {
        const response = await axios
          .create()
          .post(
            `${
              import.meta.env.VITE_PRISM_BACKEND_ROOT_ENDPOINT || 'https://backend-fftuh3xouq-uc.a.run.app'
            }/${endpoint}`,
            body
          );

        setData(response.data);
        return response.data;
      } catch (err) {
        setError(err);
      }
    },
    [endpoint]
  );

  return { postData, data, error };
};
