import ChatIcon from '@mui/icons-material/Chat';
import { Box, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FileDescription, FilePreview } from './results-preview';

export interface FileSimilaritySearchResult {
  id: string;
  content: string;
  signedSourceUrl: string;
  sourceFile: string;
  sourcePage: number;
  similarityDistance?: number;
}

// ui for displaying file results
export const FileResults = ({
  results,
  onChatClicked,
  isChatting = false
}: {
  results?: FileSimilaritySearchResult[];
  onChatClicked: () => void;
  isChatting: boolean;
}): JSX.Element => {
  const [fetchedData, setFetchedData] = useState<Record<string, Blob>>({});

  useEffect(() => {
    // Get unique URLs from results
    const uniqueUrls = results ? [...new Set(results.map(({ signedSourceUrl }) => signedSourceUrl))] : [];
    Promise.all(uniqueUrls.map((url) => fetch(url).then((res) => res.blob()))).then((blobData) => {
      const newFetchedData: Record<string, Blob> = {};
      uniqueUrls.forEach((url, index) => {
        newFetchedData[url] = blobData[index];
      });
      setFetchedData(newFetchedData);
    });
  }, [results]);

  return !results?.length ? (
    <Box>
      <Typography variant="h4">No Results</Typography>
      <Typography variant="body1">
        There are no current results matching that query. Please refine your search.
      </Typography>
    </Box>
  ) : (
    <Box>
      <Box display="flex">
        <Typography
          variant="body1"
          sx={{
            mt: 'auto',
            mb: 'auto'
          }}
        >
          Showing <strong>{results.length}</strong>
          {` Result${results.length === 1 ? '' : 's'}`}
        </Typography>

        <Tooltip title="Chat with results">
          <IconButton
            sx={{ mt: 'auto', mb: 'auto' }}
            disabled={isChatting}
            onClick={onChatClicked}
            color="primary"
            size="medium"
          >
            <ChatIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box>
        {results?.map(({ id, signedSourceUrl, sourceFile, sourcePage, content }) => (
          <>
            <Divider />
            <Box key={id} sx={{ mt: 2, mb: 4 }}>
              <Grid container columnSpacing={2}>
                <Grid item sm={12} md={4} sx={{ mt: 2 }}>
                  <FilePreview id={id} pdfBlob={fetchedData[signedSourceUrl]} pageNumber={sourcePage} />
                </Grid>
                <Grid item sm={12} md={8} sx={{ mt: 2 }}>
                  <Typography variant="caption">{sourceFile}</Typography>
                  <Typography mb={2} variant="body2">
                    Page: {sourcePage}
                  </Typography>
                  <FileDescription
                    id={id}
                    signedSourceUrl={signedSourceUrl}
                    sourceFile={sourceFile}
                    sourcePage={sourcePage}
                    content={content}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        ))}
      </Box>
    </Box>
  );
};
