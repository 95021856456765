import { ErrorContext } from '@/features/error-handling';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useContext } from 'react';
import { ErrorBundaryTypes } from '@/types/errors';

export const useAuthToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { setError } = useContext(ErrorContext);

  const fetchToken = useCallback(
    async (allowSilentFailure?: boolean) => {
      try {
        const token = await getAccessTokenSilently();
        return token;
      } catch (err) {
        if (allowSilentFailure) return;
        setError({ type: ErrorBundaryTypes.SessionExpired });
      }
    },
    [getAccessTokenSilently, setError]
  );

  return fetchToken;
};
