import { Card, Box, Typography, PaletteColor } from '@mui/material';
import { ReactNode } from 'react';

import { Link } from 'react-router-dom';

type PrismCardProps = {
  color: PaletteColor;
  content: ReactNode;
  title: string;
  label?: string;
};

/**
 * Reusable small card component with color edge
 */
export const PrismCard = ({ color, content, title, label }: PrismCardProps): JSX.Element => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Card
        sx={{
          height: 235,
          width: 230,
          borderRadius: '45px',
          dropShadow: ' 0px 0px 65px -42px #B1D1E5',
          position: 'absolute',
          backgroundColor: color.main,
          top: 0,
          bottom: 0
        }}
        elevation={0}
      ></Card>
      <Card
        elevation={0}
        sx={{
          height: 230,
          width: 230,
          backgroundColor: '#fff',
          borderRadius: '45px',
          dropShadow: ' 0px 0px 65px -42px #B1D1E5',
          position: 'absolute',
          top: 0,
          bottom: 0
        }}
      >
        <Box sx={{ position: 'relative', m: 3, height: '100%' }}>
          <Box>
            <Typography
              variant="h6"
              sx={{ fontFamily: 'Canela Text', fontSize: 18, color: color.dark, fontWeight: 300 }}
            >
              {title}
            </Typography>
          </Box>
          <Box sx={{ overflow: 'auto' }}>{content}</Box>
          {label && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 40,
                left: 0,
                backgroundColor: '#fff'
              }}
            >
              <Typography variant="caption" sx={{ color: color.dark, fontSize: 12, textTransform: 'uppercase' }}>
                {label}
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    </Box>
  );
};

/**
 * Linked card component
 */
export const LinkedPrismCard = ({ link, ...args }: { link: string } & PrismCardProps): JSX.Element => {
  return (
    <Link to={link}>
      <PrismCard {...args} />
    </Link>
  );
};
