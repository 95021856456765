import DualRing from '@/styles/loaders/dual-ring';
import { Box, Card, CircularProgress, Fade, Modal, Typography } from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import theme from '../../../../../styles/theme';
import { FileSimilaritySearchResult } from './results';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

const ResultLoading = () => {
  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      maxHeight={200}
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor="rgba(255,255,255,255)"
      zIndex={1}
    >
      <DualRing />
    </Box>
  );
};

export const FilePreview = memo(
  ({ id, pdfBlob, pageNumber = 1 }: { id: string; pdfBlob: Blob; pageNumber: number }) => {
    const [openPreview, setOpenPreview] = useState(false);
    const [docSize, setDocSize] = useState({ width: 300, height: 300 * (9 / 16) });
    const [aspectRatioSet, setAspectRatioSet] = useState(false); // New state to track if the aspect ratio has been set

    const objectURL = useMemo(() => {
      return pdfBlob ? URL.createObjectURL(pdfBlob) : null;
    }, [pdfBlob]);

    useEffect(() => {
      return () => {
        if (objectURL) {
          URL.revokeObjectURL(objectURL);
        }
      };
    }, [objectURL]);

    const handleLoadSuccess = ({ width, height }: { width: number; height: number }) => {
      if (aspectRatioSet) return;

      const aspectRatio = width / height;
      const maxWidth = 300; // adjust as needed
      const maxHeight = 300; // adjust as needed

      let computedWidth, computedHeight;

      if (aspectRatio > 1) {
        // landscape
        computedWidth = maxWidth;
        computedHeight = maxWidth / aspectRatio;
      } else {
        // portrait
        computedHeight = maxHeight;
        computedWidth = maxHeight * aspectRatio;
      }

      setDocSize({ width: computedWidth, height: computedHeight });
      setAspectRatioSet(true);
    };

    return (
      <Box key={id} display="flex">
        <Card
          key={id}
          variant="outlined"
          onClick={() => setOpenPreview(true)}
          sx={{
            backgroundColor: theme.palette.background.default,
            cursor: 'pointer',
            height: docSize.height,
            width: docSize.width,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {objectURL ? (
            <Document
              key={id}
              loading={<ResultLoading />}
              file={objectURL}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Page loading={<Box />} pageNumber={pageNumber} onLoadSuccess={handleLoadSuccess} width={docSize.width} />
            </Document>
          ) : (
            <Box />
          )}
        </Card>
        <Modal open={openPreview} onClose={() => setOpenPreview(false)} closeAfterTransition>
          <Fade in={openPreview}>
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: docSize.width / docSize.height > 1 ? '65%' : '50%',
                height: docSize.width / docSize.height > 1 ? '50%' : '65%',
                outline: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {objectURL ? (
                <Document
                  key={`preview${id}`}
                  loading={<CircularProgress />}
                  file={objectURL}
                  style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <Page loading={<Box />} pageNumber={pageNumber} />
                </Document>
              ) : (
                <Box />
              )}
            </Box>
          </Fade>
        </Modal>
      </Box>
    );
  }
);

export const FileDescription = ({ content }: FileSimilaritySearchResult): JSX.Element => {
  return (
    <>
      <Box>
        <Card
          variant="outlined"
          sx={{
            overflowY: 'scroll',
            scrollbarWidth: 'none', // Hide the scrollbar for firefox
            '&::-webkit-scrollbar': {
              display: 'none' // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
            },
            '&-ms-overflow-style:': {
              display: 'none' // Hide the scrollbar for IE
            },
            backgroundColor: theme.palette.background.paper,
            border: 'none',
            padding: 2,
            maxHeight: 200,
            height: '100%'
          }}
        >
          <Typography variant="body2">{content}</Typography>
        </Card>
      </Box>
    </>
  );
};
