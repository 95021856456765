import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import useUploadCsv from './use-upload-csv';
import { FileWithPreview } from '@/types/files';

export const useFileUpload = (projectId: string, chatId?: string) => {
  const { upload } = useUploadCsv(projectId, chatId);
  const [files, setFiles] = useState<Array<FileWithPreview>>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv']
    },
    onDrop: (acceptedFiles: File[]) => {
      setFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  const handleFileRemove = (fileToRemove: File) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  return {
    files,
    setFiles,
    handleFileRemove,
    getRootProps,
    getInputProps,
    uploadFiles: upload
  };
};
